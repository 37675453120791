import Base_Config from '../Base_Config';
import axios from 'axios';
import {  set_user_name, 
          set_account_id, 
          set_user_front_key, 
          set_accounts_available, 
          set_token, 
          set_is_auth, 
          set_loading, 
          set_alert_type, 
          set_alert_message } from '../../../redux/actions';



class Create_Record extends Base_Config
{
    constructor({ app, store })
    {
        super({ app, store });
        this.submit_create_record = this.submit_create_record.bind(this);
    }
      

    submit_create_record ({ fieldsets, record_id_source, record_is_active }) 
    {

      const app = this.app;
      const store = this.store;
      const token = store.getState()['main']['token'];

        if(token)
        {
    
         return axios({ method: 'post',
                        url: 'https://18kbsvrrd7.execute-api.us-west-2.amazonaws.com/dev/create_record',
                        headers: {
                        'Authorization': token
                        },
                        data: {
                            fieldsets,
                            record_id_source,
                            record_is_active
                        } })
                    .then(res => {
                        
                        console.log('res after create record: ', res)

                        store.dispatch(set_alert_message('')); 
                        store.dispatch(set_alert_type(''));

                        store.dispatch(set_alert_message(res['data']['message']))
                        store.dispatch(set_alert_type('primary'))

                        return true;
                    })
                    .catch(err => {
                        console.log("err in create record: ", err);

                        store.dispatch(set_alert_message('')); 
                        store.dispatch(set_alert_type(''));

                        store.dispatch(set_alert_message(err['response']['data']['message']))
                        store.dispatch(set_alert_type('danger'))
                    
                        return false
                        //return window.location.replace('/selection')
                    })
                    .then(() => {
                        
                        

                        return setTimeout(() => { store.dispatch(set_alert_message('')); 
                                                store.dispatch(set_alert_type('')); } , 15000)
                    })
        }
    }


}


export default Create_Record;