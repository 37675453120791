const firebaseConfig = {
    apiKey: "AIzaSyD-692sBMYU8f2WooXfSBID_kqKHpPzk6c",
    authDomain: "flexys-main-0.firebaseapp.com",
    databaseURL: "https://flexys-main-0.firebaseio.com",
    projectId: "flexys-main-0",
    storageBucket: "flexys-main-0.appspot.com",
    messagingSenderId: "513858619634",
    appId: "1:513858619634:web:edb188d693c59b00ece86b"
};

export default firebaseConfig