import React from 'react';
import bytes from 'bytes';
import { Row, Col, Card, Progress, CardBody, CardHeader, Input } from 'reactstrap';

export default function CompanySetup ({data_usage_bytes}) {


   

    return (
        <Card>
        <CardHeader>
          <h3>
            Company Setup
          </h3>
        </CardHeader>
        <CardBody>
          <h5>
            Company Name
          </h5>
          <Input placeholder={'some name'} />
        </CardBody>
      </Card>
    )
}


