import React from 'react';
import bytes from 'bytes';
import { Row, Col, Card, Progress, CardBody, CardHeader } from 'reactstrap';

export default function StorageWidget ({data_usage_bytes}) {

    let usage_part = Math.round((((5368709120 - parseInt(data_usage_bytes)))/5368709120)*100);
    let usage_percent = 100 - usage_part;
    let usage_status_bar = [];


    if ( usage_percent < 50)
    {
        usage_status_bar.push(<Progress bar color="primary" value={usage_percent} />)
    }

    if ( usage_percent > 50 && usage_percent < 85)
    {
        usage_status_bar.push(<Progress bar color="primary" value="50" />)
        usage_status_bar.push(<Progress bar color="warning" value={usage_percent-50} />)
    }

    if ( usage_percent > 85 )
    {
        usage_status_bar.push(<Progress bar color="primary" value="50" />)
        usage_status_bar.push(<Progress bar color="warning" value="35" />)
        usage_status_bar.push(<Progress bar color="danger" value={usage_percent-85} />)
    }

   

    return (
        <Card>
            <CardHeader>
                <div className="h3">Data Usage</div>
            </CardHeader>
            <CardBody>
            <div className="h6">Data Transfer IN and OUT</div>
            <div className="h5">{bytes(data_usage_bytes)}  / 5GB</div>
            <Progress multi>
                {
                    usage_status_bar   
                }
            </Progress>
            </CardBody>
        </Card>
    )
}


/**
 * 
 *  <Row>
        <Col>
            <div className="h6">
                <span className="bg-primary inline-block" style={{width: 10, height: 10, marginRight: 5}}></span>
                Docs
            </div>
            <small className="text-muted">106 GB</small>
        </Col>
        <Col>
            <div className="h6">
                <span className="bg-warning inline-block" style={{width: 10, height: 10, marginRight: 5}}></span>
                Video
            </div>
            <small className="text-muted">76 GB</small>
        </Col>
        <Col>
            <div className="h6">
                <span className="bg-danger inline-block" style={{width: 10, height: 10, marginRight: 5}}></span>
                Email
            </div>
            <small className="text-muted">14 GB</small>
        </Col>
    </Row>
 */