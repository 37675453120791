import React from 'react';
import { Form, FormGroup, Label, Input, Col, Row, Card, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import LayoutToUi from '../utils/layout_to_form';
import MSwitch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import { purple } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import MButton from '@material-ui/core/Button';
import MCard from '@material-ui/core/Card';
import * as Feather from 'react-feather';


/**
 * 
 * FIX THIS SHIT! TOO UGLY AND MESSY
 * 
 * 
 */



const PurpleSwitch = withStyles({
    switchBase: {
      color: '#004d54',
      '&$checked': {
        color: '#127cd8',
      },
      '&$checked + $track': {
        backgroundColor: '#127cd8',
      },
    },
    checked: {},
    track: {},
  })(MSwitch);


  const Options = withStyles({
    width: '120px',
    height: '35px',
    marginBottom: '45px',
    backgroundColor: 'rgba(255, 255, 255, .9)',
    color: 'black',
    outline: 'none',
    textAlign: 'center',
  })(MButton);




const classes = makeStyles((theme) => ({
    typography: {
      padding: theme.spacing(2),
    },
    top_controls: {
        width: '120px',
        height: '35px',
        marginBottom: '45px',
        backgroundColor: 'rgba(255, 255, 255, .9)',
        color: 'black',
        outline: 'none',
        textAlign: 'center'
      },
  }));


class New_Record extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            type_selected: 'animal_profile',
            page: '',
            record_is_active: true,
            customize_id: false,
            record_id_placeholder: '',
            record_id_value: '',
            options_dropdown: false,
            profiles: {},
            files: {},
            anchorEl: false,
            is_mobile: false,
            opts: []
        }

        this.handle_type_select = this.handle_type_select.bind(this);
        this.handle_switch_change = this.handle_switch_change.bind(this);
        this.handle_record_id_change = this.handle_record_id_change.bind(this);
        this.handle_options_toggle = this.handle_options_toggle.bind(this);
        this.handle_scroll = this.handle_scroll.bind(this);
    }


    sample_water_report = [{
        type:'layout',
        label: 'Water Quality Report',
        name: 'water_quality_report',
        access_roles: ['all'],
        layout : [
            // ROW
            {
                type: 'form',
                label: 'Primary Information',
                name: 'primary_info',
                column_count: 1,
                access_roles: ['all'],
                columns: [
                    [
                        [{
                            type: 'text',
                            label: 'Water Category',
                            name: 'category',
                            component: 'input_text',
                            access_roles: ['all'],
                            locked: true
                        }],
                        [{
                            type: 'text',
                            label: 'Water Name',
                            name: 'name',
                            component: 'input_text',
                            access_roles: ['all'],
                            locked: true
                        }]
                        ,
                        [{
                            type: 'number',
                            label: 'Age',
                            name: 'age',
                            component: 'input_text',
                            access_roles: ['all'],
                            locked: false
                        },
                        {
                            type: 'text',
                            label: 'Gender',
                            name: 'gender',
                            component: 'input_text',
                            access_roles: ['all'],
                            locked: true
                        }],
                        [{
                            type: 'blank',
                            label: 'blank',
                            name: 'blank',
                            component: 'blank_space',
                            access_roles: ['all'],
                            locked: false
                        },
                        {
                            type: 'create_button',
                            label: 'Create Record',
                            name: 'create_record',
                            component: 'create_record_button',
                            color: 'primary',
                            access_roles: ['all'],
                            locked: true
                        }]
                    ]
                ],
            },
            /*
            {
                type: 'divider',
                label: 'Default: End Of Div',
                name: 'primary_info',
                component: 'row_divider'
            },
            {
                type: 'tabs',
                label: 'Related Records and Documents',
                name: 'related_records_documents',
                component: ''
            },
            {
                type: 'divider',
                label: 'Default: End Of Div',
                name: 'primary_info',
                component: 'row_divider'
            },
            */
        ]
    }];


    sample_profile_blueprint = [{
        type:'layout',
        label: 'Animal Profile',
        name: 'animal_profile',
        access_roles: ['all','admin'],
        layout : [
            // ROW
            {
                type: 'form',
                label: 'Primary Information',
                name: 'primary_info',
                column_count: 2,
                access_roles: ['all','admin'],
                columns: [
                    [
                        [{
                            type: 'text',
                            label: 'Record ID',
                            name: 'record_id',
                            component: 'input_text',
                            access_roles: ['all','admin'],
                            locked: true,
                            constant: true,
                            placeholder: 'To Be Generated',
                            disabled: true
                        }],
                        [{ 
                            type: 'media',
                            label: 'Profile Image',
                            name: 'profile_image',
                            component: 'upload_image',
                            access_roles: ['all','admin'],
                            locked: true,
                            constant: true,
                        }]
                    ],
                    [
                        [{
                            type: 'text',
                            label: 'Record Active',
                            name: 'record_active',
                            component: 'input_text',
                            access_roles: ['all','admin'],
                            locked: true,
                            disabled: true
                        },
                        {
                            type: 'text',
                            label: 'Date Created',
                            name: 'date_created',
                            component: 'input_text',
                            access_roles: ['all','admin'],
                            locked: true,
                            constant: true,
                            placeholder: 'To Be Generated',
                            disabled: true
                        }],
                        [{
                            type: 'text',
                            label: 'Category',
                            name: 'category',
                            component: 'input_text',
                            access_roles: ['all','admin'],
                            locked: true,
                            constant: false,
                        }],
                        [{
                            type: 'text',
                            label: 'Name',
                            name: 'name',
                            component: 'input_text',
                            access_roles: ['all','admin'],
                            locked: true,
                            constant: false
                        }]
                        ,
                        [{
                            type: 'number',
                            label: 'Age',
                            name: 'age',
                            component: 'input_text',
                            access_roles: ['all','admin'],
                            locked: false
                        },
                        {
                            type: 'text',
                            label: 'Gender',
                            name: 'gender',
                            component: 'input_text',
                            access_roles: ['all','admin'],
                            locked: true
                        }],
                        [{
                            type: 'create_button',
                            label: 'Create Record',
                            name: 'create_record',
                            component: 'create_record_button',
                            color: 'primary',
                            access_roles: ['all','admin'],
                            locked: true,
                            constant: true,
                        }]
                    ]
                ],
            },
            {
                type: 'divider',
                label: 'Default: End Of Div',
                name: 'primary_info',
                component: 'row_divider'
            },
            {
                type: 'tabs',
                label: 'Related Records and Documents',
                name: 'related_records_documents',
                access_roles: ['all','admin'],
                component: ''
            },
            {
                type: 'divider',
                label: 'Default: End Of Div',
                name: 'primary_info',
                component: 'row_divider'
            },
        ]
    }];



    sample_sub_tab = [
        {
            type: 'directory',
            name: 'Medical Documents',
        },
        {
            type: 'directory',
            name: 'Medical Documents/Medical Images',
        },
        {
            type: 'directory',
            name: 'Medical Documents/Medical Videos',
        },
        {
            type: 'directory',
            name: 'Medical Documents/Medical Images/Today',
        },
        {
            type: 'file',
            name: 'Medical Documents/Medical Images/Today/sample.csv',
        },
        {
            type: 'file',
            name: 'Medical Documents/Medical Images/Today/sample1.csv',
        },
        {
            type: 'file',
            name: 'Medical Documents/Medical Images/Today/sample2.csv',
        },
        {
            type: 'file',
            name: 'Medical Documents/Medical Images/Today/sample3.csv',
        },
        {
            type: 'file',
            name: 'Medical Documents/Medical Images/Today/sample4.csv',
        },
        {
            type: 'file',
            name: 'Medical Documents/Medical Images/Today/sample5.csv',
        },
        {
            type: 'directory',
            name: 'Medical Documents/Medical Images/Yesterday',
        },
        {
            type: 'directory',
            name: 'Medical Documents/Medical Images/This Week',
        },
        {
            type: 'directory',
            name: 'Medical Documents/Medical Images/This Month',
        },
        {
            type: 'directory',
            name: 'Medical Documents/Medical Images/This Year',
        },
        {
            type: 'file',
            name: 'Medical Documents/Medical Images/image1.csv',
        },
        {
            type: 'file',
            name: 'Medical Documents/Medical Images/image2.csv',
        },
        {
            type: 'file',
            name: 'Medical Documents/Medical Images/image3.csv',
        },
        {
            type: 'file',
            name: 'Medical Documents/Medical Images/Yesterday/sample.csv',
        },
        {
            type: 'directory',
            name: 'Laboratory Results',
        },
        {
            type: 'directory',
            name: 'Laboratory Results/Lab Images',
        },
        {
            type: 'directory',
            name: 'Laboratory Results/Lab Videos',
        },
        {
            type: 'directory',
            name: 'Reports',
        },
        {
            type: 'directory',
            name: 'Reports/Reports Images',
        },
        {
            type: 'directory',
            name: 'Reports/Reports Videos',
        },
        /*
        {
            type: 'directory',
            name: 'samples',
        },
        {
            type: 'directory',
            name: 'tests',
        },
        {
            type: 'directory',
            name: 'blood',
        },
        {
            type: 'directory',
            name: 'stool',
        },
        */
    ]


    profiles = {
        'animal_profile': this.sample_profile_blueprint,
        'water_quality_report': this.sample_water_report,
    }

    tabs = {
        'animal_profile': this.sample_sub_tab,
       
    }






    componentDidMount ()
    {
        const { type_selected, record_is_active, customize_id, record_id_placeholder, record_id_value } = this.state;
        const { is_mobile, controller, layouts } = this.props;



        const profiles = layouts['layouts'];
        const files = layouts['file_layouts']
        const opts = layouts['opts']
        console.log('opts: ', opts)

        this.setState({ ...this.state,
                        type_selected,
                        controller,
                        customize_id,
                        record_is_active,
                        record_id_placeholder,
                        record_id_value,
                        is_mobile,
                        profiles,
                        files,
                        opts })



        window.addEventListener('scroll', this.handle_scroll)

    }



    componentWillUnmount()
    {
        window.removeEventListener('scroll', this.handle_scroll)
    }



    componentDidUpdate (prevProps, prevState)
    {
      
        const { type_selected, record_is_active, customize_id, record_id_placeholder, record_id_value } = this.state;
        const { is_mobile, controller, layouts } = this.props;

        const profiles = layouts['layouts'];
        const files = layouts['file_layouts']

        if ( prevProps.is_mobile !== is_mobile )
        {
            this.setState({ ...this.state, is_mobile })
        }


        if(prevProps.layouts !== layouts)
        {
            this.setState({ ...this.state, profiles, files})
        }


        if ( prevProps.controller !== controller )
        {

            this.setState({ ...this.state, controller})
        }


        /*
        if ( prevState.record_is_active !== record_is_active )
        {

            this.setState({ ...this.state, record_is_active})
        }


        if ( prevState.customize_id !== customize_id )
        {
            const place_holder = !customize_id ? 'To Be Generated' : record_id_placeholder;

            this.setState({ ...this.state, record_is_active})
        }



        if ( prevState.record_id_placeholder !== record_id_placeholder )
        {

            this.setState({ ...this.state, record_id_placeholder})
        }
        */

       
    }



    handle_type_select(event)
    {
        const type_selected = event['target']['value'];
        this.setState({ ...this.state, type_selected})
    }





    handle_scroll ()
    {
        //console.log('scroll: ', window.scrollY);
        if(window.scrollY > 90)
        {
            this.setState({...this.state, scrolled_down: true})
        }
        else
        {
            this.setState({...this.state, scrolled_down: false})
        }
    }



    handle_switch_change ()
    {

        return this.setState({...this.state, record_is_active: !this.state.record_is_active})
    }


    handle_record_id_change(event)
    {
        this.setState({ ...this.state, 
                        record_id_placeholder: event['target'].value, 
                        record_id_value: event['target'].value  })
    }


    handle_options_toggle()
    {
        this.setState({...this.state, options_dropdown: !Boolean(this.state.options_dropdown)})
    }



    handleOptionsClick = (event) => {
        this.setState({...this.state, anchorEl: event.currentTarget});
    };

    handleOptionsClose = () => {
        this.setState({...this.state, anchorEl: null});
    };



   

    render()
    {

        const { is_mobile, 
                page, 
                customize_id, 
                options_dropdown, 
                anchorEl, 
                scrolled_down,
                record_id_value,
                record_is_active,
                record_id_placeholder,
                controller, 
                files,
                profiles,
                type_selected,
                opts } = this.state;
        const { classes } = this.props;

        const open = Boolean(anchorEl);
        const id = open ? 'simple-popover' : undefined;

        const place_holder = !customize_id ? 'To Be Generated' : record_id_placeholder;

        

        const parameters = {
            is_mobile,
            record_type: profiles[type_selected], 
            sub_tabs: files[type_selected], 
            type_selected,
            controller,
            customize_id,
            record_is_active,
            record_id_placeholder: place_holder,
            record_id_value
        }



        const p = <LayoutToUi {...parameters} />

       


        
        return ( <div>
                  <Row style={!Boolean(is_mobile) ? {display: 'none'} : {}}>
                  <Col style={{transition: 'all .3s', width: '100%', position: 'fixed', top: scrolled_down ? '55px' : '80px', left: '.6px', right: '0', zIndex: '75', padding: '0', paddingRight: scrolled_down ? '5px' : '30px',}}>
                  <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end', }}>
                    <MCard aria-describedby={id} elevation={6}>
                        <Options style={{outline: 'none'}} onClick={this.handleOptionsClick}>
                            <Feather.Settings size='18' color={'#004d54'} />
                            <span style={{marginLeft: '6px'}}>Options</span>
                        </Options>
                    </MCard>
                   </div>
                
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={this.handleOptionsClose}
                        anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                        }}
                        transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                        }}
                    >
                        <Card style={!Boolean(is_mobile) ? {position: 'fixed', width: '100%'} : {}}>
                            <CardBody>

                                <Row>
                                    <Col>
                                        <h4 className='label_header' htmlFor="primary_info">Options</h4>
                                        <Form>
                                            <Row>
                                                <Col>
                                                    <FormGroup>
                                                        <Label for="type_select">Select Record Type</Label>
                                                        <Input value={this.state.type_selected} type="select" name="type_select" id="select_record_type" onChange={this.handle_type_select}>
                                                            { 
                                                                opts.map(obj => <option value={obj['name']}>{obj['label']}</option>)  
                                                            }
                                                        </Input>
                                                    </FormGroup>


                                                    <FormGroup>
                                                        <Label for="record_id">Record ID</Label>
                                                        <FormGroup check>
                                                        <Label check style={{fontSize: '14px'}}>
                                                            <Input style={{width: '15px', height: '15px', marginTop: '.1rem'}} checked={this.state.customize_id} onClick={(event) => this.setState({...this.state, customize_id: !this.state.customize_id})} type="checkbox" />{' '}
                                                            Customize
                                                        </Label>
                                                        </FormGroup>
                                                        <Input type="text" onChange={this.handle_record_id_change} name="record_id" id="record_id" placeholder={customize_id ? "Input your custom ID" : "System Generated"} disabled={!customize_id} value={ !Boolean(this.state.customize_id) ? '' : this.state.record_id_value }/>
                                                    </FormGroup>


                                                    <FormGroup>
                                                        <FormControlLabel
                                                            labelPlacement="top"
                                                            style={{alignItems: 'flex-start', marginLeft: '0px'}}
                                                            control={
                                                            <PurpleSwitch
                                                                checked={this.state.record_is_active}
                                                                onChange={this.handle_switch_change}
                                                                name="checkedB"
                                                                color="primary"
                                                            />
                                                            }
                                                            label="Set Record As Active"
                                                        />
                                                    </FormGroup>

                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </Row>

                            </CardBody>
                        </Card>
                    </Popover>
                    </Col>
                  </Row>
                  <Row>
                    
                    {/** controller */}
                    <Col sm={{ size: 3, offset: 1 }} style={is_mobile ? {maxWidth: '100%', marginLeft: '0%', flex: '0 0 100%',} : {}} >


                        <Card style={!Boolean(is_mobile) ? {position: 'fixed'} : {display: 'none'}}>
                            <CardBody>

                                <Row>
                                    <Col>
                                        <h4 className='label_header' htmlFor="primary_info">Options</h4>
                                        <Form>
                                            <Row>
                                                <Col>
                                                    <FormGroup>
                                                        <Label for="type_select">Select Record Type</Label>
                                                        <Input value={this.state.type_selected} type="select" name="type_select" id="select_record_type" onChange={this.handle_type_select}>
                                                            { 
                                                                opts.map(obj => <option value={obj['name']}>{obj['label']}</option>) 
                                                            }
                                                        </Input>
                                                    </FormGroup>


                                                    <FormGroup>
                                                        <Label for="record_id">Record ID</Label>
                                                        <FormGroup check>
                                                        <Label check style={{fontSize: '14px'}}>
                                                            <Input style={{width: '15px', height: '15px', marginTop: '.1rem'}} checked={this.state.customize_id} onClick={(event) => this.setState({...this.state, customize_id: !this.state.customize_id})} type="checkbox" />{' '}
                                                            Customize
                                                        </Label>
                                                        </FormGroup>
                                                        <Input type="text" onChange={this.handle_record_id_change} name="record_id" id="record_id" placeholder={customize_id ? "Input your custom ID" : "System Generated"} disabled={!customize_id} value={ !Boolean(this.state.customize_id) ? '' : this.state.record_id_value }/>
                                                    </FormGroup>


                                                    <FormGroup>
                                                        <FormControlLabel
                                                            labelPlacement="top"
                                                            style={{alignItems: 'flex-start', marginLeft: '0px'}}
                                                            control={
                                                            <PurpleSwitch
                                                                checked={this.state.record_is_active}
                                                                onChange={this.handle_switch_change}
                                                                name="checkedB"
                                                                color="primary"
                                                            />
                                                            }
                                                            label="Set Record As Active"
                                                        />
                                                    </FormGroup>

                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </Row>

                            </CardBody>
                        </Card>
                    </Col>



                   {/** page */}
                   <Col md={{ size: 7, offset: .5 }} style={is_mobile ? {maxWidth: '100%', marginLeft: '0%', flex: '0 0 100%', marginTop: '85px'} : {}} >


                    {/** object layout  */}
                        
                        { p }
                        
                    </Col>


                  </Row>

                 </div> )
    }
}



export default New_Record