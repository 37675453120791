import React from 'react';

export default function Avatar({ size, color, className, initials, image, email }) {
  return (
    <div className={`avatar avatar-${size} ${className}`} style={{ backgroundColor: color ? color : 'blue'  }}>
      {initials && !image && <div className={email ? "user-initials-email" : "user-initials"}>{initials}</div>}
      {image && <img src={image} className="avatar-image" alt="" />}
    </div>
  );
}


// <div className={`avatar avatar-${size} bg-${color} ${className}`}>