
import React, { useEffect } from 'react';
import MButton from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { connect } from 'react-redux';
import Loader from '../Loader';
import * as Feather from 'react-feather';
import { Alert, Button } from 'reactstrap';




const mapStateToProps = (state) => {
    return {
      is_auth: state.main.is_auth,
      accounts_available: state.main.accounts_available,
      user_email: state.main.user_name,
      loading: state.main.loading,
      alert_type: state.redux_alert.alert_type,
      alert_message: state.redux_alert.alert_message
    };
  };


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="/#">
        www.flexyz.com
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}







const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));




const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#00717b',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#004d54',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#00717b',
      },
    },
  },
})(TextField);




const ColorButton = withStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: '2.7rem',
    marginBottom: '4.5rem',
    color: 'white',
    backgroundColor: '#00717b',
    '&:hover': {
      backgroundColor: '#004d54',
    },
  },
}))(MButton);








function Sign_In({ controller, referrer, cookies, is_auth, loading, alert_message, alert_type  }) {
  const classes = useStyles();
  

  console.log('referrer in signin: ', referrer);



  useEffect(() => 
  {

      //controller.on_click({app_class: 'Authentication', btn_name: 'check_auth'})
      console.log("is_auth empty: ", is_auth)

  },[]);


  useEffect(() => 
  {

      if (is_auth)
      {
        window.location.replace('/selection')
      }


      

  },[is_auth]);



  function submit_login(e)
  {
    e.preventDefault();
    //const account = document.querySelector('#login-account').value;
    const email = document.querySelector('#login-email').value;
    const password = document.querySelector('#login-password').value;

    return controller.on_click({app_class: 'Authentication', btn_name: 'submit_login', parameters: {referrer, email, password} })
  }

  //console.log("referrer in signin: ", referrer)

  let display = <>
                <Alert color={alert_type} isOpen={Boolean(alert_message) ? true : false} class='auth-alert'>
                  <span className='alert_message'>{alert_message}</span> <MButton onClick={() => controller.set_alert({ message: false })} style={{position: 'absolute', right: '10px', top: '10px', padding: '3px', height: '30px', display: 'flex'}}><Feather.X size='24' color={'white'} /></MButton>
                </Alert> 
                <Container style={{paddingTop: '27px'}} component="main" maxWidth="xs">
                  <CssBaseline />
                  <div className={classes.paper}>

                  <Typography component="h1" variant="h5" style={{color: '#004d54'}}>
                      Sign in
                  </Typography>
                  <form onSubmit={submit_login} className={classes.form} >
                      <CssTextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="login-email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      autoFocus
                      />
                      <CssTextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="login-password"
                      autoComplete="current-password"
                      />
                      <FormControlLabel
                      control={<Checkbox value="remember" color="primary" />}
                      label="Remember me"
                      />
                      <ColorButton
                      type="submit"
                      >
                      Sign In
                      </ColorButton>
                      <Grid container>
                      <Grid item xs>
                          <Link href="#" variant="body2" style={{color: '#004d54'}}>
                          Forgot password?
                          </Link>
                      </Grid>
                      <Grid item>
                          <Link href="/signup" variant="body2" style={{color: '#004d54'}}>
                          {"Don't have an account? Sign Up"}
                          </Link>
                      </Grid>
                      </Grid>
                  </form>
                  </div>
                  <Box mt={8}>
                  <Copyright />
                  </Box>
                </Container>
                </>

  
  if(Boolean(loading))
  {
    display = <Loader/>
  }


  return display;
}




export default connect(mapStateToProps, null)(Sign_In);


/*
import React, {Component} from 'react';
import { Button, Form, FormGroup, Input, Card, CardBody, CardFooter } from 'reactstrap';


export default class FormsPage extends Component {

  constructor(props) {
    super(props);
    this.state = { }
  }

  handle_sign_in (e)
  {
    e.preventDefault();
    const { controller, referrer } = this.props;
    const account = document.querySelector('#login-account').value;
    const email = document.querySelector('#login-email').value;
    const password = document.querySelector('#login-password').value;

    return controller.on_click({app_class: 'Authentication', btn_name: 'submit_login', parameters: {referrer, account, email, password} })

  }

  render() 
  {
    return (
      <div className='login-container'>

        <Card id='login-card'>
          <CardBody>
            <Form id='login-form' onSubmit={this.handle_sign_in}>
              <FormGroup>
                <h2 id='login-title'>FLEXYZ</h2>
              </FormGroup>

              <FormGroup>
                <Input type='text' name='login-account' id='login-account' placeholder='Account' />
              </FormGroup>

              <FormGroup>
                <Input type='text' name='login-email' id='login-email' placeholder='Email' />
              </FormGroup>

              <FormGroup>
                <Input type='text' name='login-password' id='login-password' placeholder='Password' />
              </FormGroup>

              <Button color='primary'>Login</Button>
            </Form>
          </CardBody>
          <CardFooter id='login-card-footer'>
            <a href='/#'>Forgot password?</a>
            <a href='/#'>Don't have an account? Sign up!</a>
          </CardFooter>
        </Card>
         
      </div>
    )
  }

}
*/