import Base_Config from '../Base_Config';
import axios from 'axios';
import {
    set_alert_message,
    set_alert_type,
    set_records_details,
    set_loading,
    set_record_blueprints
} from '../../../redux/actions';


class Get_Record_Blueprints extends Base_Config
{
    constructor({app, store})
    {
        super({ app, store });
       
    }

    get_record_blueprints () 
    {

      const app = this.app;
      const store = this.store;
      const token = store.getState()['main']['token'];
      

        if(token)
        {
            
            return axios({ method: 'post',
                            url: 'https://cqmqjhcna0.execute-api.us-west-2.amazonaws.com/dev/get_record_blueprints',
                            headers: {
                                'Authorization': token
                            },
                            data: {
                            get_record_blueprints: 'get_record_blueprints'
                            } 
                        }).then(res => {
                                
                            
                            store.dispatch(set_alert_message('')); 
                            store.dispatch(set_alert_type(''));

                            store.dispatch(set_record_blueprints(res['data']['data']));
                            store.dispatch(set_alert_message(res['data']['message']))
                            store.dispatch(set_alert_type('primary'))
                            

                            return res['data'];
                        })
                        .catch(err => {
                            console.log("err in get_records_blueprints: ", err);

                            store.dispatch(set_alert_message('')); 
                            store.dispatch(set_alert_type(''));

                            console.log('error in get records blueprints: ', err)
                            store.dispatch(set_alert_message('Something went wrong. Please try again later.'))
                            store.dispatch(set_alert_type('danger'))
                            
                        
                            return false
                            //return window.location.replace('/selection')
                        })
                        .then(() => {

                            
                            return setTimeout(() => { store.dispatch(set_alert_message('')); 
                                                    store.dispatch(set_alert_type('')); } , 15000)
                        })
        }
    
    
    }
}


export default Get_Record_Blueprints;