import React from 'react';
import { SubNavigation } from '../../../vibe';
import Browse_Record_Types from './Browse_Record_Types';
import Browse_Record_Collections from './Browse_Record_Collections';
import queryString from 'query-string';
import { connect } from 'react-redux';



const mapStateToProps = (state) => {
  return {
    token: state.main.token,
  };
};



const NAV = [
  {
    name: 'Record Types',
    url: '/records/browse/types',
    component: Browse_Record_Types
  },
  {
    name: 'Record Collections',
    url: '/records/browse/collections',
    component: Browse_Record_Collections
  }
];

class Browse_Records extends React.Component {

  constructor(props)
  {
    super(props);
    this.state = {

    }
  }



  load_defaults()
  {
    const { controller, token } = this.props;
    const qs = queryString.parse(window.location.search)

    if(token)
    {
     // controller.get_records_summary();
      if(qs['record_type'] && controller)
      {
       // controller.on_click({app_class: 'Get_Sample_Selection', btn_name: 'get_records', parameters: {type_selected: qs['record_type']} }) 
      }
    }
  }


  componentDidMount()
  {

   // this.load_defaults()

  }


  componentDidUpdate(prevProps, prevState)
  {
    const { token } = this.props;

    if(prevProps.token !== token)
    {
     // this.load_defaults()
    }
    
  }



  render()
  {
    
    return <SubNavigation {...this.props} navList={NAV} />;
  }
  
}


export default connect(mapStateToProps, null)(Browse_Records);