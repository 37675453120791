import React, { useEffect, useState } from 'react';
import {Redirect } from 'react-router-dom';
import Loader from '../Loader';
import { makeStyles } from '@material-ui/core/styles';
import MCard from '@material-ui/core/Card';
import MCardHeader from '@material-ui/core/CardHeader';
import MCardActionArea from '@material-ui/core/CardActionArea';
import MCardContent from '@material-ui/core/CardContent';
import MCardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import MButton from '@material-ui/core/Button';
import queryString from 'query-string';
import RecordsCollectionTable from '../../tables/records_collection_table';
import { connect } from 'react-redux';
import * as Feather from 'react-feather';



const mapStateToProps = (state) => {
  return {
    records_details: state.record_details.records_details,
    is_loading: state.main.is_loading,
    token: state.main.token,
    column_headers: state.record_details.browse_records_column_headers,
    browse_records: state.record_details.browse_records,
  };
};


const useStyles = makeStyles({
  type_card: {
    width: '280px',
    height: 'auto',
    marginLeft: '10%',
    marginRight: '15px',
    marginBottom: '45px',
    backgroundColor: 'rgba(255, 255, 255, .9)',
    color: 'black',
    outline: 'none'
  },
  top_controls: {
    width: '120px',
    height: '35px',
    marginBottom: '45px',
    backgroundColor: 'rgba(255, 255, 255, .9)',
    color: 'black',
    outline: 'none',
    textAlign: 'center'
  },
  def_card: {
    width: '600px',
    backgroundColor: 'rgba(255, 255, 255, .9)',
    color: 'black',
    //visibility: 'hidden'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 16,
  },
  description: {
    fontSize: 14,
    color: 'black'
  },
  pos: {
    marginBottom: 12,
  },
  cardHeader: {
    color: 'black',
    display: 'flex',
    textAlign: 'left',
    borderBottom: '1px solid black',
    fontSize: '16px'
  },
  cardAction: {
    marginTop: 'auto',
    justifyContent: 'flex-end',
    outline: 'none'
  },
  cardContent: {
    textAlign: 'left',
    height: 'auto'
  },
  btn: {
    color: 'black'
  }
});









const Browse_Record_Collections = ({ records_details, controller, isMobile, subnav_selected, column_headers, browse_records, token }) => {
  let classes = useStyles();

  const [state, setState] = useState({
      record_list: [],
      type_selected: false,
      query: false,
      record_type: false
  })


  useEffect(() => {
   
    const qs = queryString.parse(window.location.search)

    if(token)
    {
      controller.get_records_summary();
      if(qs['record_type'] && controller)
      {
        controller.on_click({app_class: 'Get_Sample_Selection', btn_name: 'get_records', parameters: {type_selected: qs['record_type']} }) 
      }
    }

  }, [token])





  useEffect(() => {
   
    setState({...state, record_list: records_details})

  }, [records_details])


  useEffect(() => {
    const qs = queryString.parse(window.location.search)
    if(state['type_selected'] && state['query'] && window.location.search)
    {
      
      setState({  ...state, 
                  type_selected: false, 
                  query: false, 
                  record_type: qs['record_type']})
    }
    else if (!state['type_selected'] && !state['query'] && window.location.search)
    {
     
      setState({  ...state, 
                  record_type: qs['record_type'] })
    }
    else if (!state['type_selected'] && state['query'] && window.location.search)
    {
      controller.on_click({app_class: 'Get_Sample_Selection', btn_name: 'get_records', parameters: {type_selected: qs['record_type']} })
      setState({  ...state, 
                  query: false,
                  record_type: qs['record_type'] })
    }
  }, [state['query']])


  useEffect(() => {

    setState({  ...state, 
                record_list: records_details, 
                record_type: false  })
    
  }, [subnav_selected])


  useEffect(() => {

    const qs = queryString.parse(window.location.search)
    if(qs['record_type'])
    {
      controller.on_click({app_class: 'Get_Sample_Selection', btn_name: 'get_records', parameters: {type_selected: qs['record_type']} }) 
    }

    setState({  ...state, 
                record_list: records_details, 
                type_selected: false, 
                query: false, 
                record_type: qs['record_type'] })
    
  }, [state['record_type']])




  function get_sample_record({type_selected})
  {
    if(controller)
    {
      setState({...state, type_selected, query: true})
      //controller.on_click({app_class: 'Get_Sample_Selection', btn_name: 'get_records', parameters: {type_selected} }) 
    }
    return false;
  }




  if(state['type_selected'] && state['query'])
  {
    return <Redirect to={`/records/browse/collections?record_type=${state['type_selected']}`} />
  }


  if(state['record_type'])
  {
    if (column_headers && column_headers.length <= 0 || browse_records && browse_records <= 0)
    {
      return <Loader height={'45vh'} />
    }

    return <RecordsCollectionTable isMobile={isMobile} columns={column_headers} data={browse_records} />
  }
 


  return (
    <div style={{width: '100%'}}>
      <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end', }}>
        <MCard className={classes.top_controls} elevation={6}>
          <MButton style={{width: '100%', outline: 'none'}} onClick={() => controller.get_records_summary()}>
            <Feather.RefreshCcw size='18' color={'#004d54'} />
            <span style={{marginLeft: '6px'}}>Reload</span>
          </MButton>
        </MCard>
      </div>
      <div style={{height: 'auto', width: '100%', display: 'flex', flexFlow: 'row wrap', justifyContent: 'center', paddingRight: isMobile ? '5%' : '10%'}} >
      {
        state['record_list'].length <= 0 ?
        <Loader height={'30vh'} /> :
        state['record_list'].map(record => 
        <MCard className={classes.type_card} elevation={3}>
          <MCardActionArea className={classes.cardAction} onClick={() => get_sample_record({type_selected: record['record_type']})}>
          <MCardMedia
              style={{width: '100%', height: '160px'}}
              image={encodeURI(decodeURI("https://images.pexels.com/photos/64219/dolphin-marine-mammals-water-sea-64219.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"))}
              title="Contemplative Reptile"
            />
              
            <MCardContent className={classes.cardContent}>
              <Typography gutterBottom variant="h5" component="h2">
                { record['label'] }
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                 Total: {record['record_count']}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                 Active: {record['active_count']}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                 Inactive: {record['inactive_count']}
              </Typography>
            </MCardContent>
          </MCardActionArea>
        </MCard>)
      }
      </div>
    </div>
  );
};

export default connect(mapStateToProps, null)(Browse_Record_Collections);
