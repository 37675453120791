import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Dashboard_Layout from './layouts/Dashboard_Layout';
import Signin from './views/pages/authentication/Sign_In';
import Selection from './views/pages/authentication/Sign_In_Selection';
import Signup from './views/pages/authentication/Sign_Up';
import Controller from './controller/animalia/Controller'
import './vibe/scss/styles.scss';

//import sample_mod from './sample_mod'


import { withCookies } from 'react-cookie';


const controller = new Controller();






function App(props) {

  //sample_mod()

  /*
  import('https://adam-extended-modules.s3-us-west-2.amazonaws.com/sample_mod.js')
  .then(sample_mod => {
    sample_mod('hello world!')
  }) */


  console.log("referrer: ", window.location.href);

  const href = window.location.href;
  const path = window.location.pathname;
  const DashboardLayout = Dashboard_Layout;
  const cookies = props.cookies;

  controller.check_auth();
  

  if( [`/selection`, '/null', '/signin', '/signup'].filter(p => p === path).length === 0 && !Boolean(sessionStorage.getItem('selection')))
  { 
    sessionStorage.setItem('r', href)
  }
  
 
  

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/signup" render={ props => Boolean(sessionStorage.getItem('auth')) && !Boolean(sessionStorage.getItem('selection')) ? 
                                                      <Redirect to={href} /> :
                                                      Boolean(sessionStorage.getItem('auth')) && Boolean(sessionStorage.getItem('selection')) ? 
                                                      <Redirect to="/selection" /> :
                                                      <Signup {...props}  controller={controller} cookies={cookies} />} />

        <Route exact path="/signin" render={ props => 
                                            Boolean(sessionStorage.getItem('auth')) && !Boolean(sessionStorage.getItem('selection')) ? 
                                            <Redirect to={href} /> :
                                            Boolean(sessionStorage.getItem('auth')) && Boolean(sessionStorage.getItem('selection')) ? 
                                            <Redirect to="/selection" /> :
                                            <Signin controller={controller} referrer={href} cookies={cookies} /> } />

        <Route exact path="/selection" render={ props => 
                                            Boolean(sessionStorage.getItem('auth')) && !Boolean(sessionStorage.getItem('selection')) ? 
                                            <Redirect to={href} /> :
                                            !Boolean(sessionStorage.getItem('auth')) && !Boolean(sessionStorage.getItem('selection')) ? 
                                            <Redirect to="/signin" /> :
                                            <Selection controller={controller} referrer={href} cookies={cookies} /> } />

        <Route component={ props => 
                           Boolean(sessionStorage.getItem('auth')) && !Boolean(sessionStorage.getItem('selection')) ? 
                           <DashboardLayout {...props} controller={controller} cookies={cookies}/> : 
                           !Boolean(sessionStorage.getItem('auth')) && !Boolean(sessionStorage.getItem('selection')) ? 
                           <Redirect to="/signin" /> : 
                           <Redirect to="/selection" /> } />
      </Switch>
    </BrowserRouter>
  );
}

export default withCookies(App);