import React, { Component } from 'react';
import classnames from 'classnames';
import * as Feather from 'react-feather';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import BreadCrumbsPage from './Breadcrumbs';
import MButton from '@material-ui/core/Button';



class TabsPage extends Component 
{


  constructor(props) 
  {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: 0,
      crumb_count: {},
      crumb_links:{},
      nav: '',
      panels: '', 
      sub_tabs: [],
      contents: [],
      selectedTab: ''
    };

    this.create_tabs = this.create_tabs.bind(this);
    this.move_dir_in = this.move_dir_in.bind(this);
    this.move_dir_out = this.move_dir_out.bind(this);

  }



  toggle(tab) 
  {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }


  move_dir_in ({index, name})
  {
    const { activeTab, crumb_links, crumb_count } = this.state;
    this.setState({ ...this.state, 
                    crumb_count: { ...crumb_count, 
                                   [activeTab]: crumb_count[activeTab]+1 },
                                   crumb_links: { ...crumb_links, 
                                                  [activeTab]: [ ...crumb_links[activeTab], name ] }  })
  }

  move_dir_out({index, name})
  {
    const { activeTab, crumb_links, crumb_count } = this.state;
    this.setState({ ...this.state, 
                    crumb_count: { ...crumb_count, 
                                    [activeTab]: ( crumb_count[activeTab]-(crumb_count[activeTab]-index)+1) }, 
                                    crumb_links:{ ...crumb_links, 
                                                  [activeTab]: [ ...crumb_links[activeTab].slice(0,index+1)] }  })
  }

  // CREATE TABS METHOD
  create_tabs({ sub_tabs, name })
  {


    
    if(sub_tabs && sub_tabs.length > 0)
    {
     
      let nav = sub_tabs.filter(tab => (tab['name'].split('/').length === 1));
      let crumbs = this.state.crumb_links;
      let counts = this.state.crumb_count;
                                
      
      const nav_items = nav.map( (tab, index) => 
                                {

                                  crumbs[index] = crumbs[index] && crumbs[index].length > 1 ? crumbs[index] : [tab['name']];
                                  counts[index] = counts[index] && counts[index] > 1 ? counts[index] : 1;

                                  return (<NavItem >
                                            <NavLink
                                              style={{height: '80px', width: '150px', textAlign: 'center'}}
                                              href="#"
                                              className={classnames({ active: this.state.activeTab === index })}
                                              onClick={() => {
                                                this.toggle(index);
                                              }}
                                            >
                                              {
                                                tab['name']
                                              }
                                            </NavLink>
                                          </NavItem>)
                                })
     
    
      const selectedTab = nav[this.state.activeTab]['name'];

      const f = sub_tabs.filter(tab => (tab['name'].split('/').length > 1))

      let n = crumbs
                          
      let crumb_count = counts;


      this.setState({ 
                      ...this.state, 
                      nav: nav_items,
                      crumb_count, 
                      crumb_links: n,
                      contents: f,
                      selectedTab
                    })
    }

  }



  componentDidMount() 
  {
    const { sub_tabs, is_mobile } = this.props;

    this.create_tabs({ sub_tabs });
    //this.setState({ ...this.state, is_mobile })
    //this.create_tab_contents({ sub_tabs });

  }


  componentDidUpdate(prevProps, prevState)
  {

    if(prevState.activeTab !== this.state.activeTab)
    {
      const { sub_tabs } = this.props;

      this.create_tabs({ sub_tabs});
      //this.create_tab_contents({ sub_tabs });
    }

    if(prevState.sub_tabs !== this.state.sub_tabs)
    {
      const { sub_tabs } = this.props;

      this.create_tabs({ sub_tabs});
      //this.create_tab_contents({ sub_tabs });
    }

    if(prevProps.is_mobile !== this.props.is_mobile)
    {
      const { is_mobile } = this.props;

      this.setState({ ...this.state, is_mobile })
      //this.create_tab_contents({ sub_tabs });
    }

  }


  render() 
  {
    const { contents, crumb_links, activeTab, is_mobile } = this.state;
    

    
    return (
      <div>
       <Nav tabs className="file_tab_nav">{this.state.nav}</Nav>
       {
         <TabContent className="file_tab_content" activeTab={this.state.activeTab}>
         {
           contents.length > 0 ?
           contents.map((tab, index) =>
           {
               return <TabPane tabId={index}>
                         <Row>
                           <Col>
                             <BreadCrumbsPage  crumb_links={crumb_links[activeTab]} move_dir_out={this.move_dir_out} />
                           </Col>
                         </Row>
                         <div style={{display: 'flex', flexFlow: 'row wrap', justifyContent: 'flex-start'}}>
                           {
                             contents.filter(o => o['type'] === 'directory').map( (obj, index) => 
                             {
                               if(this.state.crumb_links[activeTab] && obj['name'].split('/')[this.state.crumb_count[activeTab]-1] === this.state.crumb_links[activeTab][this.state.crumb_links[activeTab] ? this.state.crumb_links[activeTab].length-1 : 0] && obj['name'].split('/').length === this.state.crumb_count[activeTab]+1)
                               {
                                 return <div>
                                        {

                                          obj['type'] === 'directory'?
                                          <MButton className='folder_buttons' variant='contained' onClick={(e) => new Promise(resolve => {
                                              return setTimeout(() => resolve(true),450)
                                            })
                                            .then(res => {
                                              this.move_dir_in({ event: e, name: obj['name'].split('/')[this.state.crumb_count[activeTab]], index })
                                            })} style={{width: '160px', margin: '18px'}}>

                                            <div>           
                                              <Feather.Folder size='80' color={'#004d54'} />
                                            </div>

                                            <span className='folder_name'>{ obj['name'].split('/')[this.state.crumb_count[activeTab]] }</span>
                                                  
                                          </MButton>
                                          : ''
                                        }
                                        </div>
                       
                                 }

                                 return '';
                               })
                                 
                           }
                         </div>    
                         <div style={{display: 'flex', flexFlow: 'row wrap', justifyContent: 'flex-start', marginTop: '45px'}}>
                           {
                             contents.filter(o => o['type'] === 'file').map( (obj, index) => 
                             {
                               if(this.state.crumb_links[activeTab] && obj['name'].split('/')[this.state.crumb_count[activeTab]-1] === this.state.crumb_links[activeTab][this.state.crumb_links[activeTab] ? this.state.crumb_links[activeTab].length-1 : 0] && obj['name'].split('/').length === this.state.crumb_count[activeTab]+1)
                               {
                                 /*
                                 return <Col xs="12" sm="6" md="3" lg="2">
                                 
                                        {

                                          obj['type'] === 'file'?
                                          <div style={{width: '90px', textAlign: 'left', margin: '.1rem'}}>
                                              <a href="#">
                                                <Feather.FileText size='80' color={'#004d54'} />
                                                <h6 className='file_name'>{ obj['name'].split('/')[this.state.crumb_count[activeTab]] }</h6>
                                              </a>
                                            </div>
                                          : ''             
                                        }
                                        </Col>
                                    */


                                        return <div>
                                        {

                                          obj['type'] === 'file'?
                                          <MButton className='folder_buttons' 
                                                   variant='contained' 
                                                   onClick={(e) => console.log('file clicked')} 
                                                   style={{width: '160px', margin: '1rem', backgroundColor: 'white'}}>

                                            <div>           
                                              <Feather.FileText size='80' color={'#004d54'} />
                                            </div>

                                            <span className='folder_name'>{ obj['name'].split('/')[this.state.crumb_count[activeTab]] }</span>
                                                  
                                          </MButton>
                                          : ''
                                        }
                                        </div>
                       
                                 }

                                 return '';
                               })
                                 
                           }
                         </div>    
                       </TabPane>
           }) : ''
         }
         </TabContent>
       } 
      </div>
    );
  }
}

export default TabsPage;
