import {  set_data_usage_bytes } from '../../../../redux/actions';

function Get_Dashboard ({ fb_app, store })
{
    const account_id = store.getState()['main']['account_id'];
    const user_front_key = store.getState()['main']['user_front_key'];


    return fb_app.database()
                .ref(`registered_accounts/${account_id}`)
                .child('usage')
                .child('data_usage_bytes')
                .on('value', data => {
                    let val = data.val()
                    store.dispatch(set_data_usage_bytes(val))
                })
    
}


export default Get_Dashboard;