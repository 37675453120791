import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MCard from '@material-ui/core/Card';
import MCardHeader from '@material-ui/core/CardHeader';
import MCardActions from '@material-ui/core/CardActions';
import MCardContent from '@material-ui/core/CardContent';
import MButton from '@material-ui/core/Button';
import RecordTypesTable from '../../tables/record_types_table';


const useStyles = makeStyles({
  type_card: {
    width: '600px',
    marginBottom: '45px',
    backgroundColor: 'rgba(255, 255, 255, .9)',
    color: 'black',
  },
  def_card: {
    width: '600px',
    backgroundColor: 'rgba(255, 255, 255, .9)',
    color: 'black',
    //visibility: 'hidden'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 16,
  },
  description: {
    fontSize: 14,
    color: 'black'
  },
  pos: {
    marginBottom: 12,
  },
  cardHeader: {
    color: 'black',
    display: 'flex',
    textAlign: 'left',
    borderBottom: '1px solid black',
    fontSize: '16px'
  },
  cardAction: {
    marginTop: 'auto',
    justifyContent: 'flex-end',
  },
  cardContent: {
    textAlign: 'left',
    height: '500px'
  },
  btn: {
    color: 'black'
  }
});


const Browse_Record_Types = () => {
  let classes = useStyles();

  return (
    <div style={{width: '100%', display: 'flex', flexFlow: 'row wrap', justifyContent: 'space-around'}}>
      <MCard className={classes.type_card} elevation={0}>
        <MCardContent className={classes.cardContent}>
          <RecordTypesTable />
        </MCardContent>
        <MCardActions className={classes.cardAction}>
          <MButton className={classes.btn} 
                  size='small'>
             Add New
          </MButton>
        </MCardActions>
      </MCard>

      <MCard className={classes.def_card} elevation={0}>
        <MCardHeader
          className={classes.cardHeader}
          title="Definition"
        />
        <MCardContent className={classes.cardContent}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi id neque quam. Aliquam-a-0 m-b-smitudin
          egestas dui nec, fermentum diam. Vivamus vel tincidunt libero, vitae elementu
        </MCardContent>
      </MCard>
    </div>
  );
};

export default Browse_Record_Types;
