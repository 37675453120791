import React, { useState } from 'react';
import * as Feather from 'react-feather';
import { Row, Col, Card, Input, CardHeader, CardBody } from 'reactstrap';
import TabWidget from './widgets/TabWidget';
import { connect } from 'react-redux';
import DataUsageWidget from './widgets/DataUsageWidget';
import CompanySetup from './widgets/CompanySetup';
import UserAccounts from './widgets/UserAccounts';
import { Carousel } from 'react-responsive-carousel';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';



const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    borderLeft: '1px solid rgb(0, 77, 84)',
    borderBottom: '1px solid rgb(0, 77, 84)',
    padding: '15px 0 6px 0'  
  },
}));




const mapStateToProps = (state) => {
  return {
    data_usage_bytes: state.main.data_usage_bytes,
  };
};



function Settings({ data_usage_bytes }) {


  return (
    <Row>
      <Col md={4}>
        <DataUsageWidget data_usage_bytes={data_usage_bytes} />
        <CompanySetup />
      </Col>
      <Col md={4}>
        <UserAccounts />
      </Col>
      <Col md={3}>
          <Card body>
           
          </Card>
      </Col>
    </Row>
  );
}

export default connect(mapStateToProps, null)(Settings);

/*
export default function Widgets() {
  return (
    <Row>
      <Col md={4}>
        <TrafficWidget />
        <ProfileWidget />
      </Col>
      <Col md={4}>
        <AnalyticsWidget />
        <StorageWidget />
      </Col>
      <Col md={4}>
        <Card body>
          <div className="text-center">
            <h2 className="h5 text-muted text-uppercase">Bounce Rate</h2>
            <span className="h2">
              <Feather.BarChart2 /> 2.7%
            </span>
          </div>
        </Card>
        <TabWidget />
        <Card body>
          <div>
            <span className="h4">
              <Feather.Headphones /> 3 Missed Messages
            </span>
          </div>
        </Card>
        <RatingWidget />
      </Col>
    </Row>
  );
}
*/