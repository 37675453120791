import React from 'react';
import UploadImage from './Upload_Image';
import CreateRecord from './Create_Record_Button';
import InputText from './Input_Text';
import Blankspace from '././Blank_Space';




const Field_Components = {
    'upload_image': (param) => <UploadImage {...param} />,
    'create_record_button':  (param) => CreateRecord(param),
    'input_text': (param) => <InputText {...param} />,
    'blank_space':  (param) => Blankspace(param)
}


export default Field_Components;