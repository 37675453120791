import React from 'react';
import * as Feather from 'react-feather';
import { Button, Label, UncontrolledPopover, PopoverHeader, PopoverBody, Input, Col, Row } from 'reactstrap';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';




    
class Upload_Image extends React.Component {

        constructor(props)
        {
            super(props);

            this.state = {
                image_link: 'https://via.placeholder.com/700X500?text=No+Image+Available'
            };

            this.handle_change = this.handle_change.bind(this);
        }


        handle_change(event)
        {
            const input_value = event.target.value;

            const link = input_value && input_value !== '' ?  
                         encodeURI(decodeURI(input_value)) :
                         'https://via.placeholder.com/700X500?text=No+Image+Available'
            
            this.setState({image_link: link})

        }



        
        handleClick = (event) => {
            this.setState({...this.state, anchorEl: event.currentTarget});
        };
    

        handleClose = () => {
            this.setState({...this.state, anchorEl: null});
        };



        render()
        {
            const { fields, record_id_placeholder, record_is_active, form_ident } = this.props;
            const { anchorEl } = this.state;
    
            const LockImage = fields['locked'] ? Feather.Lock : Feather.Unlock;
            const open = Boolean(anchorEl);
            const id = open ? 'image-pop' : undefined;

            return <>
                    <Label htmlFor={fields['name']+'_ins'} style={{width: '100%'}}>
                        {fields['label']}
                        <IconButton onClick={this.handleClick} aria-describedby={id} variant="contained" style={{outline: 'none', width: '42px', height: '42px',position: 'absolute', top: '-10px', right: '0'}} aria-label="more">
                            <Feather.MoreHorizontal size='18' color={'#004d54'} className="lock_icon" />
                        </IconButton>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={this.state.anchorEl}
                            onClose={this.handleClose} 
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            >
                            {
                               <Row>
                                 <Col colSpan="2" style={{padding: '12px 10%', maxWidth: '320px', minHeight: '60px', display: 'flex', flexFlow: 'column nowrap', justifyContent: 'center'}}>
                                    <h5 style={{width: '100%'}}>Name: {fields['name']}</h5>

                                    <div style={{width: '100%', display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-between'}}>
                                        <LockImage size='20' style={{color: fields['locked'] ? fields['constant'] ? '#c43b00' : '#d4b400' : '#004d54'}} />
                                        <span style={{width: '85%', marginLeft: '0'}}>
                                            {
                                                fields['locked'] ?
                                                fields['constant'] ? 
                                                <p>Modifying this field after creation is not allowed.</p> :
                                                <p>Modifying this field after creation is restricted to allowed roles.</p> :
                                                <p>Modifying this field after creation is allowed.</p>
                                            }
                                        </span>
                                    </div>
                                 </Col>
                               </Row>
                            }
                        </Popover>
                    </Label>
                    <img width="100%" 
                         name={fields['name']} 
                         src={this.state.image_link} 
                         data-form-ident={form_ident}
                         data-form-type={fields['type']} 
                         data-form-label={fields['label']} 
                         data-form-name={fields['name']} 
                         data-form-access-roles={fields['access_roles'].join(',')}
                         data-form-record-id={record_id_placeholder}
                         data-form-record-active={record_is_active.toString()}
                         data-form-component={fields['component']} 
                         data-form-placeholder={fields['placeholder'] ? fields['placeholder'] : fields['label']} 
                         data-form-value={this.state.image_link}  
                         placeholder={fields['placeholder'] ? fields['placeholder'] : fields['label']}
                         alt={fields['alt'] ? fields['alt'] : fields['label']}
                         style={{ marginRight: 'auto', marginLeft: 'auto' }} 
                        />

                    <div style={{width: '100%', textAlign: 'center'}}>
                        <Button id={fields['name']} style={{marginTop: '10px', color: 'black'}}>Attach Image</Button>
                    </div>
                    <UncontrolledPopover trigger="legacy" placement="top" target={fields['name']}>
                        <PopoverHeader style={{color: '#004d54'}} >Attach Image</PopoverHeader>
                        <PopoverBody>
                            <Input type='text' placeholder={'Image Link'} style={{width: '100%'}} onChange={this.handle_change} />
                            <h5 style={{width: '100%', textAlign: 'center', margin: '.5rem 0'}}> - or - </h5>
                            <Button color={'primary'} style={{width: '100%'}}>
                                <Feather.Upload size='24' color={'white'} />
                                &nbsp;
                                Upload From Local
                            </Button>
                        </PopoverBody>
                    </UncontrolledPopover>
                  </>
        }

}
    
    
            



export default Upload_Image;



/**
 * 
 * <>
                <Label htmlFor={fields['name']}><LockImage size='24' color={fields['locked'] ? '#8f9400' : '#004d54'} className="lock_icon" />{fields['label']}</Label>
                <img width="100%" name="profile_image" id="profile_image" style={{ marginRight: 'auto', marginLeft: 'auto' }} src={image_link} alt="Dolphin" />
                <div style={{width: '100%', textAlign: 'center'}}>
                    <Button id={fields['name']} style={{marginTop: '10px', color: 'black'}}>Upload an Image</Button>
                </div>
                <UncontrolledPopover trigger="legacy" placement="top" target={fields['name']}>
                    <PopoverHeader style={{color: '#004d54'}} >Attach Image</PopoverHeader>
                    <PopoverBody>
                        <Input type='text' placeholder={'Image Link'} style={{width: '100%'}} onChange={handle_change} />
                        <h5 style={{width: '100%', textAlign: 'center', margin: '.5rem 0'}}> - or - </h5>
                        <Button color={'primary'} style={{width: '100%'}}>
                            <Feather.Upload size='24' color={'white'} />
                            &nbsp;
                            Upload From Local
                        </Button>
                    </PopoverBody>
                </UncontrolledPopover>
           </>
 * 
 */