import React from 'react';
import bytes from 'bytes';
import { Row, Col, Card, Progress, CardBody, CardHeader, Input } from 'reactstrap';

export default function UserAccounts ({data_usage_bytes}) {


   

    return (
        <Card>
        <CardHeader>
          <h3>
            Accounts
          </h3>
        </CardHeader>
        <CardBody>
          <Input placeholder={'some name'} />
          <div style={{width: '100%', height: '160px', overflow: 'scroll', border: '1px solid black'}}>
          </div>
        </CardBody>
      </Card>
    )
}


