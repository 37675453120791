import React from 'react';
import * as Feather from 'react-feather';
import { Input, Label} from 'reactstrap';




function Blank_Space({fields})
{
    
    return  <>
                <div style={{width: '100%'}} color={fields['color'] ? fields['color'] : 'white'} />
            </> 
}


export default Blank_Space;



 