

function Set_Status_Online ({ fb_app, store })
{
    const account_id = store.getState()['main']['account_id'];
    const user_front_key = store.getState()['main']['user_front_key'];


    return fb_app.database()
                .ref(`registered_accounts/${account_id}`)
                .child('users')
                .child(user_front_key)
                .update({logged_in: 'True'})
    
}


export default Set_Status_Online;