import { ALERT_MESSAGE,
         ALERT_TYPE } from "../constants/action-types";


const initialState = {
    alert_type: '',
    alert_message: ''
};


const redux_alert = (state = initialState, action) => {

let response;

switch (action.type) {

case ALERT_MESSAGE:
   response = { ...state, alert_message: action.payload };
break;

case ALERT_TYPE:
   response = { ...state, alert_type: action.payload };
break;



default:
 response = state;
break;

}

return response;
};
//------


export default redux_alert
