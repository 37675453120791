import React from 'react';
import MButton from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Alert } from 'reactstrap';
import * as Feather from 'react-feather';
import { connect } from 'react-redux';




const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#00717b',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#004d54',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#00717b',
      },
    },
  },
})(TextField);



const ColorButton = withStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: '2.7rem',
    marginBottom: '4.5rem',
    color: 'white',
    backgroundColor: '#00717b',
    '&:hover': {
      backgroundColor: '#004d54',
    },
  },
}))(MButton);



const mapStateToProps = (state) => {
  return {
    alert_type: state.redux_alert.alert_type,
    alert_message: state.redux_alert.alert_message
  };
};




function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        www.flexyz.com
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function Sign_Up({ controller, alert_type, alert_message }) {
  const classes = useStyles();


  function submit_signup(e)
  {
    e.preventDefault();
    const company_name = document.querySelector('#signup-company-name').value;
    const email = document.querySelector('#signup-email').value;
    const password = document.querySelector('#signup-password').value;
    const c_password = document.querySelector('#signup-password-confirm').value;

    return controller.on_click({app_class: 'Authentication', btn_name: 'submit_signup', parameters: {company_name, email, password, c_password} })
  }


  return (  <>
            <Alert color={alert_type} isOpen={Boolean(alert_message) ? true : false} class='auth-alert'>
              <span className='alert_message'>{alert_message}</span> <MButton onClick={() => controller.set_alert({ message: '', type: '' })} style={{position: 'absolute', right: '10px', top: '10px', padding: '3px', height: '30px', display: 'flex'}}><Feather.X size='24' color={'white'} /></MButton>
            </Alert> 
            <Container style={{paddingTop: '27px'}} component="main" maxWidth="xs">
              <CssBaseline />
              <div className={classes.paper}>
                <Typography component="h1" variant="h5" style={{color: '#004d54'}}>
                  Sign up
                </Typography>
                <form onSubmit={submit_signup}  className={classes.form} noValidate>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <CssTextField
                        variant="outlined"
                        required
                        fullWidth
                        id="signup-company-name"
                        label="Company Name"
                        name="email"
                        autoComplete="email"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CssTextField
                        variant="outlined"
                        required
                        fullWidth
                        id="signup-email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CssTextField
                        variant="outlined"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="signup-password"
                        autoComplete="current-password"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CssTextField
                        variant="outlined"
                        required
                        fullWidth
                        name="confirm password"
                        label="Confirm Password"
                        type="password"
                        id="signup-password-confirm"
                      />
                    </Grid>
                  </Grid>
                  <ColorButton
                    type="submit"
                  >
                    Sign Up
                  </ColorButton>
                  <Grid container justify="center">
                    <Grid item>
                      <Link href="/signin" style={{color: '#004d54'}} variant="body2">
                        Already have an account? Sign in
                      </Link>
                    </Grid>
                  </Grid>
                </form>
              </div>
              <Box mt={5}>
                <Copyright />
              </Box>
            </Container>
            </>);
}


export default connect(mapStateToProps, null)(Sign_Up);