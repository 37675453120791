import Base_Config from '../Base_Config';
import axios from 'axios';
import {
    set_alert_message,
    set_alert_type,
    set_records_details,
    set_loading
} from '../../../redux/actions';


class Get_Records_Summary extends Base_Config
{
    constructor({app, store})
    {
        super({ app, store });
       
    }

    get_records_summary () 
    {

      const app = this.app;
      const store = this.store;
      const token = store.getState()['main']['token'];
        
        if(token)
        {
        
            return axios({ method: 'post',
                            url: 'https://hm1tx2utph.execute-api.us-west-2.amazonaws.com/dev/get_records_summary',
                            headers: {
                                'Authorization': token
                            },
                            data: {
                            get_records: 'get_records'
                            } 
                        }).then(res => {
                                
                            console.log('res after get_records_summary: ', res)

                            store.dispatch(set_alert_message('')); 
                            store.dispatch(set_alert_type(''));

                            store.dispatch(set_records_details(res['data']['data']));
                            store.dispatch(set_alert_message(res['data']['message']))
                            store.dispatch(set_alert_type('primary'))
                            

                            return res['data'];
                        })
                        .catch(err => {
                            console.log("err in get_records_summary: ", err);

                            store.dispatch(set_alert_message('')); 
                            store.dispatch(set_alert_type(''));

                            store.dispatch(set_alert_message(err['response']['data']['message']))
                            store.dispatch(set_alert_type('danger'))
                            
                        
                            return false
                            //return window.location.replace('/selection')
                        })
                        .then(() => {
                            
                            
                            
                            return setTimeout(() => { store.dispatch(set_alert_message('')); 
                                                    store.dispatch(set_alert_type('')); } , 15000)
                        })
        }
    
    
    }
}


export default Get_Records_Summary;