// src/js/store/index.js
import { createStore, compose } from "redux";
//import { persistStore, persistReducer } from 'redux-persist'
//import storage from 'redux-persist/lib/storage'
import rootReducer from "../reducers/index";

/*
const persistConfig = {
  key: 'root',
  storage,
}
*/


const store = createStore(
  rootReducer,

  
  // dev tools mapping
  compose(
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
  

);


/*
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer);
const persistor = persistStore(store);
 
export { store, persistor }
*/

export default store;
