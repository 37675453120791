import React, {Component} from 'react';
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
} from 'reactstrap';

export default class BreadcrumbsPage extends Component {
  constructor() {
    super();
    this.state = { 
      crumb_links: []
    }

    this.return_to_link = this.return_to_link.bind(this)
  }


  return_to_link(event, index, name)
  {

    event.preventDefault();

    const { move_dir_out } = this.props;


    move_dir_out({index, name});
  }


  componentDidMount()
  {

    const { crumb_links } = this.props;

    this.setState({...this.state, crumb_links})
  }

  componentDidUpdate(prevProps, prevState)
  {

    const { crumb_links } = this.props;

    if (prevProps.crumb_links !== crumb_links)
    {
      this.setState({...this.state, crumb_links})
    }
    
  }


  render() {

    const { crumb_links } = this.state;

    if(crumb_links && crumb_links.length > 0)
    {
      return (
        <div>
            <Card className='bread_crumb'>
                <CardBody>
                  <Breadcrumb>
  
                      {
                        crumb_links.map((name, index) => 
                        {
                          if (index === (crumb_links.length-1))
                          {
                            return <BreadcrumbItem active={true}>{name}</BreadcrumbItem>
                          }
                          return <BreadcrumbItem><a href="?bcri=name" onClick={e => this.return_to_link(e, index, name)}>{name}</a></BreadcrumbItem>
                        })
                      }
  
                  </Breadcrumb>
                </CardBody>
            </Card>
        </div>
      )
    }
    else
    {
      return <div></div>
    }
   
  }
}
