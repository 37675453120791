import Base_Config from '../Base_Config';
import axios from 'axios';
import {
    set_alert_message,
    set_alert_type,
    set_browse_records,
    set_browse_records_column_headers,
} from '../../../redux/actions';


class Get_Sample_Selection extends Base_Config
{
    constructor({app, store})
    {
        super({ app, store });
        this.get_records = this.get_records.bind(this);
    }

    get_records({ type_selected })
    {

        const store = this.store;
        const token = store.getState()['main']['token'];
        
        if(token)
        {
            return axios({ method: 'post',
                            url: 'https://uz92n4pn69.execute-api.us-west-2.amazonaws.com/dev/get_sample_record_selected',
                            headers: {
                            'Authorization': token
                            },
                            data: {
                                type_selected
                            } 
                        })
                .then(res => {
                    
                    console.log('res after get samples: ', res)
                    console.log('retrived data: ', res['data']['data'])

                    const raw_set = res['data']['data']

                    store.dispatch(set_alert_message('')); 
                    store.dispatch(set_alert_type(''));

                    store.dispatch(set_alert_message(res['data']['message']))
                    store.dispatch(set_alert_type('primary'))

                    
                    const col_headers = [];
                    const browse_data = raw_set.map(record => {
                        const d_set = {};
                        const column_headers = [];
                        const hidden_fields = ['access_roles', 'profile_image'];
                        
                        for( let field of record)
                        {
                            d_set['Record Id'] = field['record_id'];
                            d_set['Date Created'] = field['date_created'];
                            d_set['Access Roles'] = field['access_roles'];
                            d_set[field['label']] = field['value'];
                            column_headers.push({
                                name: field['label'],
                                options: {
                                  filter: true,
                                  sort: field['name'] === 'profile_image' ? false : 
                                        field['name'] === 'gender' ? false : true,
                                  viewColumns: true,
                                  display: hidden_fields.filter(n => n === field['name']).length > 0 ? false : true
                                }
                              })
                        }



                        column_headers.unshift({
                            name: 'Access Roles',
                            options: {
                              filter: true,
                              sort:  true,
                              viewColumns: true,
                              display:  false
                            }
                        })



                        column_headers.unshift({
                            name: 'Date Created',
                            options: {
                              filter: true,
                              sort:  true,
                              viewColumns: true,
                              display:  true
                            }
                        })

                        column_headers.unshift({
                            name: 'Record Id',
                            options: {
                              filter: true,
                              sort: true,
                              viewColumns: true,
                              display: true
                            }
                        })

                     

                        //d_set['raw'] = record

                        if(col_headers.length <= 0)
                        {
                            col_headers.push(column_headers)  
                        }
                        
                        return d_set;
                    })


                    console.log('browse_data: ', col_headers[0])
                    console.log('browse_data: ', browse_data)
                    store.dispatch(set_browse_records_column_headers(col_headers[0]))
                    store.dispatch(set_browse_records(browse_data))


                    

                    return true;
                })
                .catch(err => {
                    console.log("err in get samples: ", err);

                    store.dispatch(set_alert_message('')); 
                    store.dispatch(set_alert_type(''));

                    store.dispatch(set_alert_message(err['response']['data']['message']))
                    store.dispatch(set_alert_type('danger'))
                
                    return false
                    //return window.location.replace('/selection')
                })
                .then(() => {
                    
                    

                    return setTimeout(() => { store.dispatch(set_alert_message('')); 
                                              store.dispatch(set_alert_type('')); } , 15000)
                })
        }
        
      
    }
}


export default Get_Sample_Selection;