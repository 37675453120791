import Dashboard from './pages/Dashboard';
import Settings from './pages/Settings';
import Browse_Records from './pages/browse/Browse_Records';
import Browse_Record_Types from './pages/browse/Browse_Record_Types';
import Browse_Record_Collections from './pages/browse/Browse_Record_Collections';
import Buttons from './elements/Buttons';
import Alerts from './elements/Alerts';
import Grid from './elements/Grid';
import Typography from './elements/Typography';
import Cards from './elements/Cards';
import Tabs from './elements/Tabs';
import Tables from './elements/Tables';
import Breadcrumbs from './elements/Breadcrumbs';
import Forms from './elements/Forms';
import Loaders from './elements/Loaders';
import Avatars from './elements/Avatars';
import Invoice from './pages/Invoice';
import Analytics from './pages/Analytics';
import CmsPage from './pages/Cms';
import Widgets from './pages/Widgets';
import BlankPage from './pages/Blank_Page';
import SubNav from './pages/Sub_Nav';
import Feed from './pages/Feed';
import Modals from './elements/Modals';
import ProgressBars from './elements/ProgressBars';
import PaginationPage from './elements/Pagination';
import ErrorPage from './pages/404';
import Login from './pages/authentication/Sign_In';
import SignInSelection from './pages/authentication/Sign_In_Selection';
import Builder from './pages/builder/Builder';
import CreateNewRecord from './pages/create_record/New_Record';




// See React Router documentation for details: https://reacttraining.com/react-router/web/api/Route
const Page_List = [
  {
    name: 'Login',
    path: '/signin',
    component: Login,
  },
  {
    name: 'Account Selection',
    path: '/account_selection',
    component: SignInSelection,
  },
  {
    name: 'Dashboard',
    path: '/home',
    component: Dashboard,
  },
  {
    name: 'New Record',
    path: '/records/new',
    component: CreateNewRecord,
  },
  {
    name: 'Browse',
    path: '/records/browse',
    component: Browse_Records,
  },
  {
    name: 'Types',
    path: '/records/browse/types',
    component: Browse_Record_Types,
  },
  {
    name: 'Collections',
    path: '/records/browse/collections',
    component: Browse_Record_Collections,
  },
  {
    name: 'Record Builder',
    path: '/builder',
    component: Builder,
  },
  {
    name: 'Settings',
    path: '/settings',
    component: Settings,
  },
  {
    name: 'Buttons',
    path: '/elements/buttons',
    component: Buttons,
  },
  {
    name: 'Alerts',
    path: '/elements/alerts',
    component: Alerts,
  },
  {
    name: 'Grid',
    path: '/elements/grid',
    component: Grid,
  },
  {
    name: 'Typography',
    path: '/elements/typography',
    component: Typography,
  },
  {
    name: 'Cards',
    path: '/elements/cards',
    component: Cards,
  },
  {
    name: 'Tabs',
    path: '/elements/tabs',
    component: Tabs,
  },
  {
    name: 'Tables',
    path: '/elements/tables',
    component: Tables,
  },
  {
    name: 'Progress Bars',
    path: '/elements/progressbars',
    component: ProgressBars,
  },
  {
    name: 'Pagination',
    path: '/elements/pagination',
    component: PaginationPage,
  },
  {
    name: 'Modals',
    path: '/elements/modals',
    component: Modals,
  },
  {
    name: 'Breadcrumbs',
    path: '/elements/breadcrumbs',
    component: Breadcrumbs,
  },
  {
    name: 'Forms',
    path: '/elements/forms',
    component: Forms,
  },
  {
    name: 'Loaders',
    path: '/elements/loaders',
    component: Loaders,
  },
  {
    name: 'Avatars',
    path: '/elements/avatars',
    component: Avatars,
  },
  {
    name: 'Blank',
    path: '/pages/blank',
    component: BlankPage,
  },
  {
    name: 'Sub Navigation',
    path: '/pages/subnav',
    component: SubNav,
  },
  {
    name: 'Analytics',
    path: '/apps/analytics',
    component: Analytics,
  },
  {
    name: 'Activity Feed',
    path: '/apps/feed',
    component: Feed,
  },
  {
    name: 'Invoice',
    path: '/apps/invoice',
    component: Invoice,
  },
  {
    name: 'CMS',
    path: '/apps/cms',
    component: CmsPage,
  },
  {
    name: 'Widgets',
    path: '/widgets',
    component: Widgets,
  },
  {
    name: '404',
    path: '/pages/404',
    component: ErrorPage,
  },
];

export default Page_List;
