import { RECORDS_DETAILS,
         SAMPLE_SELECTED_RECORD,
         BROWSE_RECORDS,
         BROWSE_RECORDS_COLUMN_HEADERS,
         RECORD_BLUEPRINTS } from "../constants/action-types";


const initialState = {
    records_details: [],
    sample_selected_record: [],
    browse_records: [],
    browse_records_column_headers: [],
    record_blueprints: []
};


const records_details = (state = initialState, action) => {

let response;

switch (action.type) {

case RECORDS_DETAILS:
   response = { ...state, records_details: action.payload };
break;

case SAMPLE_SELECTED_RECORD:
   response = { ...state, sample_selected_record: action.payload };
break;

case BROWSE_RECORDS:
   response = { ...state, browse_records: action.payload };
break;

case BROWSE_RECORDS_COLUMN_HEADERS:
   response = { ...state, browse_records_column_headers: action.payload };
break;

case RECORD_BLUEPRINTS:
   response = { ...state, record_blueprints: action.payload };
break;



default:
 response = state;
break;

}

return response;
};
//------


export default records_details
