export const IS_AUTH = "IS_AUTH";
export const USER_NAME = "USER_NAME";
export const SIDEBAR_OPEN = "SIDEBAR_OPEN";
export const ACCOUNT_ID = "ACCOUNT_ID";
export const USER_FRONT_KEY = "USER_FRONT_KEY";
export const ACCOUNTS_AVAILABLE = "ACCOUNTS_AVAILABLE";
export const TOKEN = "TOKEN";
export const LOADING = "LOADING";
export const ALERT_MESSAGE = "ALERT_MESSAGE";
export const ALERT_TYPE = "ALERT_TYPE";
export const REFERRER = "REFERRER";
export const RECORDS_DETAILS = "RECORDS_DETAILS";
export const SAMPLE_SELECTED_RECORD = "SAMPLE_SELECTED_RECORD";
export const BROWSE_RECORDS = "BROWSE_RECORDS";
export const BROWSE_RECORDS_COLUMN_HEADERS = "BROWSE_RECORDS_COLUMN_HEADERS";
export const DATA_USAGE_BYTES = "DATA_USAGE_BYTES";
export const RECORD_BLUEPRINTS = "RECORD_BLUEPRINTS";

