import Base_Config from '../Base_Config';
import {
    set_alert_message,
    set_alert_type
} from '../../../redux/actions';


class Alert extends Base_Config
{
    constructor({app, store})
    {
        super({ app, store });
        this.set_alert = this.set_alert.bind(this);
    }

    set_alert({ type, message })
    {

        const store = this.store;
        
        store.dispatch(set_alert_message(message));
        store.dispatch(set_alert_type(type))
      
    }
}


export default Alert;