import { combineReducers } from 'redux'
import main from './main';
import redux_alert from './alert';
import record_details from './record_details';

let rootReducer = combineReducers({
  main,
  redux_alert,
  record_details
})


export default rootReducer;
