import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { Nav, NavItem } from 'reactstrap';
import BrowseRecordTypes from '../../../views/pages/browse/Browse_Record_Types';




export default class SubNavigation extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            display: <BrowseRecordTypes />,
            subnav_selected: false
        }

        this.on_select = this.on_select.bind(this);
    }

    on_select(e, name)
    {
        //e.preventDefault();
        this.setState({...this.state, subnav_selected: !this.state.subnav_selected})
        console.log("subnavigation changed: e, name: ", e + "  ||||  " + name)
    }

    render()
    {
        const { navList, location, controller, isMobile } = this.props;
        const { home_selected, subnav_selected } = this.state
        
        
        return ( <>
                    <div className="page-sub-nav">
                    <Nav pills>
                        {navList.map((item, index) => {
                        return (
                            <NavItem key={index}>
                                <NavLink to={item.url} onClick={(e) => this.on_select(e, item.name)} className="nav-link" activeClassName="active">
                                    {item.name}
                                </NavLink>
                            </NavItem>
                        );
                        })}
                    </Nav>

                    
                    </div>
                    <div className="page-sub-nav-body">
                        <Switch>
                            {navList.map((item, index) => {
                                const Comp = item.component
                                return <Route exact path={item.url} render={ props => <Comp subnav_selected={subnav_selected} controller={controller} isMobile={isMobile} home_selected={home_selected} />} key={index} />
                            })}
                        </Switch>
                    </div>
                </> )
    }
}




/*


const sub_nav_page = {
    'Record Types': <Browse_Record_Types />,
    'Collections': <Browse_Record_Types />
}


function on_select()
{

}



export default function SubNavigation({ navList }) {
  return (
    <>
        <div className="page-sub-nav">
        <Nav pills>
            {navList.map((item, index) => {
            return (
                <NavItem key={index}>
                    <NavLink onClick={() => on_select(item.name)} className="nav-link" activeClassName="active">
                        {item.name}
                    </NavLink>
                </NavItem>
            );
            })}
        </Nav>

        
        </div>
        <div className="page-sub-nav-body">
           {  }
        </div>
    </>
  );
}

<Nav pills>
    {navList.map((item, index) => {
    return (
        <NavItem key={index}>
            <NavLink to={item.url} className="nav-link" activeClassName="active">
                {item.name}
            </NavLink>
        </NavItem>
    );
    })}
</Nav>

<Switch>
    {navList.map((item, index) => (
    <Route exact path={item.url} component={item.component} key={index} />
    ))}
</Switch>
*/