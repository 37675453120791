import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './registerServiceWorker';
import { Provider } from 'react-redux';
//import { store, persistor } from './redux/store';
import store from './redux/store';
//import { PersistGate } from 'redux-persist/integration/react'
import App from './App';
import { CookiesProvider } from 'react-cookie';
import "react-responsive-carousel/lib/styles/carousel.min.css";

ReactDOM.render( 
    <CookiesProvider>
        <Provider store={store}>
        
            <App/>

        </Provider>
    </CookiesProvider>
, document.getElementById('app'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
