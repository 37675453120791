import { IS_AUTH, 
         USER_NAME,
         SIDEBAR_OPEN,
         ACCOUNT_ID,
         USER_FRONT_KEY,
         ACCOUNTS_AVAILABLE,
         TOKEN,
         LOADING,
         REFERRER,
         DATA_USAGE_BYTES } from "../constants/action-types";


const initialState = {
  is_auth: false,
  user_name: '',
  sidebar_open: false,
  account_id: false,
  user_front_key: false,
  accounts_available: [],
  token: '',
  loading: false,
  alert: '',
  referrer: '',
  data_usage_bytes: 0
};


const main = (state = initialState, action) => {

  let response;

  switch (action.type) {

    case IS_AUTH:
        response = { ...state, is_auth: action.payload };
    break;

    case USER_NAME:
        response = { ...state, user_name: action.payload };
    break;

    case SIDEBAR_OPEN:
        response = { ...state, sidebar_open: action.payload };
    break;

    case ACCOUNT_ID:
        response = { ...state, account_id: action.payload };
    break;

    case USER_FRONT_KEY:
        response = { ...state, user_front_key: action.payload };
    break;

    case ACCOUNTS_AVAILABLE:
      response = { ...state, accounts_available: action.payload };
    break;

    case TOKEN:
      response = { ...state, token: action.payload };
    break;

    case LOADING:
      response = { ...state, loading: action.payload };
    break;

    case REFERRER:
      response = { ...state, referrer: action.payload };
    break;

    case DATA_USAGE_BYTES:
      response = { ...state, data_usage_bytes: action.payload };
    break;
    
    default:
      response = state;
    break;
 
  }

  return response;
};
//------


export default main
