import React, { useState } from 'react';
import * as Feather from 'react-feather';
import { Row, Col, Card } from 'reactstrap';
import TabWidget from './widgets/TabWidget';
import { connect } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';



const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    borderLeft: '1px solid rgb(0, 77, 84)',
    borderBottom: '1px solid rgb(0, 77, 84)',
    padding: '15px 0 6px 0'  
  },
}));




const mapStateToProps = (state) => {
  return {
    data_usage_bytes: state.main.data_usage_bytes,
  };
};



function Widgets({ data_usage_bytes }) {

  const classes = useStyles();
  const [state, setState] = useState({
    'selected_carousel': '0',
    '0_title': 'General',
    '1_title': 'Water Quality' 
  })

  const style_arr = []


  for(let i = 0; i < 2; i++)
  {
    if (i == parseInt(state['selected_carousel']))
    {
      style_arr.push({ width: '100%', 
                       display: 'flex', 
                       justifyContent: 'center', 
                       color: 'rgb(0, 77, 84)', 
                       boxShadow: '-3px 3px 6px 1px rgb(0, 77, 84)' })
    }
    else
    {
      style_arr.push({ width: '100%', 
                       display: 'flex', 
                       justifyContent: 'center', 
                       color: 'rgb(0, 77, 84)' })
    }
    
  }

  return (
    <Row>
      <Col md={4}>
        <TabWidget />
        <Card body>
          <div>
            <span className="h4">
              <Feather.AlertCircle /> Important
            </span>
          </div>
        </Card>
      </Col>
      <Col md={6}>
        <Card body>
          <h3 style={{marginBottom: '25px'}}>{state[`${state['selected_carousel']}_title`]}</h3>
          <div style={{width: '95%', alignSelf: 'center'}}>
            <Carousel showStatus={false}
                      swipeable={true}
                      emulateTouch={true}
                      autoPlay={true}
                      stopOnHover={true}
                      infiniteLoop={true}
                      useKeyboardArrows={true}
                      dynamicHeight={false} >
              <div >
                  <img src="https://images.pexels.com/photos/443446/pexels-photo-443446.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" />
                  <p className="legend">Legend 1</p>
              </div>
              <div >
                  <img src="https://images.pexels.com/photos/531321/pexels-photo-531321.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" />
                  <p className="legend">Legend 2</p>
              </div>
              <div >
                  <img src="https://images.pexels.com/photos/1402787/pexels-photo-1402787.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" />
                  <p className="legend">Legend 3</p>
              </div>
            </Carousel>
          </div>
        </Card>
      </Col>
      <Col md={2}>
          <Card body>
            <div style={{minHeight: '120px', display: 'flex', flexFlow: 'column nowrap', justifyContent: 'space-around'}}>
              <Button onClick={() => setState({...state,  selected_carousel: '0'})} style={style_arr[0]} color="info"><span style={{fontSize: '1rem'}}>General</span></Button>
              <Button onClick={() => setState({...state,  selected_carousel: '1'})} style={style_arr[1]} color="info"><span style={{fontSize: '1rem'}}>Water Quality</span></Button>
            </div>
          </Card>
      </Col>
    </Row>
  );
}

export default connect(mapStateToProps, null)(Widgets);

/*
export default function Widgets() {
  return (
    <Row>
      <Col md={4}>
        <TrafficWidget />
        <ProfileWidget />
      </Col>
      <Col md={4}>
        <AnalyticsWidget />
        <StorageWidget />
      </Col>
      <Col md={4}>
        <Card body>
          <div className="text-center">
            <h2 className="h5 text-muted text-uppercase">Bounce Rate</h2>
            <span className="h2">
              <Feather.BarChart2 /> 2.7%
            </span>
          </div>
        </Card>
        <TabWidget />
        <Card body>
          <div>
            <span className="h4">
              <Feather.Headphones /> 3 Missed Messages
            </span>
          </div>
        </Card>
        <RatingWidget />
      </Col>
    </Row>
  );
}
*/