import React from 'react';
import ReactLoading from 'react-loading';



const Loader = ({height}) => {


  return (
    <div style={{width: '100%', height: height?height:'80vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <ReactLoading type={'cylon'} color={'white'} height={40} width={100} />
    </div>
  );
};

export default Loader;

//#004d54