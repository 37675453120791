import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Button, Badge, NavItem, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Header, SidebarNav, Footer, PageContent, Avatar, Chat, PageAlert, Page } from '../vibe';
import MButton from '@material-ui/core/Button';
import Logo from '../assets/images/vibe-logo.svg';
import avatar1 from '../assets/images/avatar1.png';
import nav from '../_nav';
import routes from '../views';
import ContextProviders from '../vibe/components/utilities/ContextProviders';
import handleKeyAccessibility, { handleClickAccessibility } from '../vibe/helpers/handleTabAccessibility';
import { connect } from 'react-redux';
import Loader from '../views/pages/Loader';
import { UncontrolledAlert, Alert } from 'reactstrap';
import * as Feather from 'react-feather';




const MOBILE_SIZE = 992;

const mapStateToProps = (state, ownProps) => {
  return {
    is_auth: state.main.is_auth,
    sidebar_open: state.main.sidebar_open,
    user_email: state.main.user_name,
    loading: state.main.loading,
    alert_type: state.redux_alert.alert_type,
    alert_message: state.redux_alert.alert_message
  };
};



class DashboardLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebar_open: false,
      isMobile: false,
      showChat1: true,
      refresh_time: 0,
      is_loggedin: false
    };
  }

  handleResize = () => {
    if (window.innerWidth <= MOBILE_SIZE) {
     
      this.setState({ isMobile: true });
      this.props.controller.on_click({ app_class: 'Sidebar', btn_name: 'toggle_open', parameters: { open: false } })
    } else {
     
      this.setState({ isMobile: false });
      this.props.controller.on_click({ app_class: 'Sidebar', btn_name: 'toggle_open', parameters: { open: true } })
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isMobile && prevProps.location.pathname !== this.props.location.pathname) {
     
      this.props.controller.on_click({ app_class: 'Sidebar', btn_name: 'toggle_open', parameters: { open: false } })
    }
   
  }

  


  componentDidMount() {
   
    window.addEventListener('resize', this.handleResize);
    document.addEventListener('keydown', handleKeyAccessibility);
    document.addEventListener('click', handleClickAccessibility);

    if(!Boolean(sessionStorage.getItem('r')))
    {
      const href = window.location.href;
      sessionStorage.setItem('r', href)
    }
    

    //this.props.controller.on_click({app_class: 'Authentication', btn_name: 'check_auth'})

    

    let refresh_token =  setInterval(() => 
                          {
                            
                            let r_time = this.state.refresh_time 

                            this.setState({...this.state, refresh_time: (r_time += 1)})
                            console.log('dashboard trigger refresh! ', this.state.refresh_time)
                            if(this.state.refresh_time >= 58)
                            {
                              console.log('refreshing token from dashboard')
                              this.setState({...this.state, refresh_time:0})
                              this.props.controller.on_click({app_class: 'Authentication', btn_name: 'refresh_token'})
                            }                            
                          }, 60000)


    this.setState({...this.state, refresh_token})
    
    this.handleResize();

    
  }

  componentWillUnmount() {
  
    window.removeEventListener('resize', this.handleResize);

    clearInterval(this.state.refresh_token);
  }

  toggleSideCollapse = () => {
    
    this.props.controller.on_click({ app_class: 'Sidebar', btn_name: 'toggle_open', parameters: { open: !this.props.sidebar_open } })
  };

  closeChat = () => {
    this.setState({ showChat1: false });
  };






  render() {
    const { controller, user_email, sidebar_open, loading, alert_type, alert_message } = this.props;
    const sidebarCollapsedClass = sidebar_open ? 'side-menu-collapsed' : '';
    

      return (
        <ContextProviders>
          <div className={`app ${sidebarCollapsedClass}`}>
            <Alert style={{right: '0', top: this.state.isMobile ? '50px': '0', width: !this.state.isMobile ? sidebar_open ? '100%' : '85%' : '100%'}} color={alert_type} isOpen={Boolean(alert_message)} class='auth-alert'>

                <span className='alert_message'>{alert_message}</span> 
                <MButton onClick={() => controller.set_alert({ message: '', type: '' })} 
                         style={{ position: 'absolute', 
                                  right: '10px', 
                                  top: '10px', 
                                  padding: '3px', 
                                  height: '30px', 
                                  display: 'flex' }}>
                <Feather.X size='24' color={'white'} />
                </MButton> 
              
            </Alert> 
            <PageAlert />
            <div className="app-body" style={{paddingTop: Boolean(alert_message) ? '55px': '0'}}>
              <SidebarNav
                nav={nav}
                logo={Logo}
                logoText="FLEXYZ"
                isSidebarCollapsed={sidebar_open}
                toggleSidebar={this.toggleSideCollapse}
                {...this.props}
              />
            {
              loading ?
              <Loader /> :
              <Page>
                <Header
                  toggleSidebar={this.toggleSideCollapse}
                  isSidebarCollapsed={sidebar_open}
                  isMobile={this.state.isMobile}
                  routes={routes}
                  {...this.props}
                >
                  <HeaderNav controller={controller} email={user_email} cookies={this.props.cookies} routes={routes} />
                </Header>
                <PageContent>
                  
                    <Switch>
                      {routes.map((page, key) => (
                        <Route path={page.path} render={(props => <page.component isMobile={this.state.isMobile} {...props} controller={controller}/>)} key={key} />
                      ))}
                      
                      <Redirect from="/" to="/home" />
                    </Switch>
                
                </PageContent>
              </Page>
            }
            </div>
            <Footer isMobile={this.state.isMobile}>
              <span>Copyright © 2020 Flexyz. All rights reserved.</span>
              <span>
                <a href="#!">Terms</a> | <a href="#!">Privacy Policy</a>
              </span>
            </Footer>
            <Chat.Container>
              {this.state.showChat1 && (
                <Chat.ChatBox name="Messages" status="online" image={avatar1} close={this.closeChat} />
              )}
            </Chat.Container>
          </div>
        </ContextProviders>
      )
   
   
  }
}



function HeaderNav({ controller, email, cookies }) {
  return (
    <React.Fragment>


      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav caret>
          Options
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem>Project</DropdownItem>
          <DropdownItem>User</DropdownItem>
          <DropdownItem divider />
          <DropdownItem>
            Message <Badge color="primary">10</Badge>
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>


      <NavItem>
        <form className="form-inline">
          <input style={{width: '180px'}} className="form-control mr-sm-1" type="search" placeholder="Search" aria-label="Search" />
          <Button type="submit" className="d-none d-sm-block">
            <i className="fa fa-search" />
          </Button>
        </form>
      </NavItem>

      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav>
          <Avatar size="email" color="rgb(0, 77, 84)" initials={email} email={true} />
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem>Option 1</DropdownItem>
          <DropdownItem>Option 2</DropdownItem>
          <DropdownItem divider />
          <DropdownItem onClick={() => {
              cookies.remove('t')
              controller.on_click({app_class: 'Authentication', btn_name: 'submit_logout', parameters: {cookies} })
          }}>Logout</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </React.Fragment>
  );
}




export default connect(mapStateToProps, null)(DashboardLayout);