import Base_Config from '../Base_Config';
import axios from 'axios';
import {  set_user_name, 
          set_account_id, 
          set_user_front_key, 
          set_accounts_available, 
          set_token, 
          set_is_auth, 
          set_loading, 
          set_alert_type, 
          set_alert_message } from '../../../redux/actions';



class Authentication extends Base_Config
{
    constructor({ app, store })
    {
        super({ app, store });
        this.check_auth = this.check_auth.bind(this);
        this.refresh_token = this.refresh_token.bind(this);
        this.submit_login = this.submit_login.bind(this);
        this.submit_selection = this.submit_selection.bind(this);
        this.submit_signup = this.submit_signup.bind(this);
    }

    check_auth({ triggers_online, cookies })
    {

        
        const store = this.store;
        const fb_app = this.app;
        
        let auth = false;

        store.dispatch(set_loading(true))

        this.app.auth().onAuthStateChanged(function(user)
        {
                        if (user) {
                            // User is signed in.
                            
                            // _user['displayName'] = user.displayName;
                            // _user['email'] = user.email;
                            // _user['emailVerified'] = user.emailVerified;
                            // _user['photoURL'] = user.photoURL;
                            // _user['isAnonymous'] = user.isAnonymous;
                            // _user['uid'] = user.uid;
                            // _user['providerData'] = user.providerData;
                            
                            
                          
                            user.getIdTokenResult().then((token) => {
                              
                              //console.log("user claims: ", token['claims'])
                              
                              const claims = token['claims'];
                              sessionStorage.setItem('auth', 'true');
                              
                              //store.dispatch(set_token(token))

                              if(claims['accounts_available'])
                              {
                                store.dispatch(set_accounts_available(claims['accounts_available']))
                                return sessionStorage.setItem('selection', 'true');
                              }


                              if(!Boolean(claims['accounts_available']) && Boolean(sessionStorage.getItem('selection')))
                              {
                                sessionStorage.removeItem('selection')
                                window.location.replace('/');
                              }

                            
                              
                              
                              store.dispatch(set_user_name(claims['user_email']));
                              store.dispatch(set_account_id(claims['account_id']));
                              store.dispatch(set_user_front_key(claims['front_key']));
                              
                            });
                            
                            store.dispatch(set_is_auth(true))
                            
                            fb_app.auth()
                                  .currentUser
                                  .getIdToken(true)
                                  .then(function(idToken) {
                                 
                                    return store.dispatch(set_token(idToken))
                                  })
                                  .then(() => {
                                    if(triggers_online && Boolean(sessionStorage.getItem('selection')) !== true)
                                    {
                                     
                                      for (let t in triggers_online)
                                      {
                                        //console.log('t in triggers: ', t)
                                        triggers_online[t]({fb_app, store})
                                      }
                                    }
                                    return true;
                                  })
                                  .catch( err => {
                                    console.log("error refreshing token from check_auth: ", err)
                                  });
                            
                           
                            
                            if(sessionStorage.getItem('r') && !Boolean(sessionStorage.getItem('selection')))
                            {
                              //store.dispatch(set_loading(true))
                              const url = new URL(sessionStorage.getItem('r'))
                              const session_path = url.pathname

                                if( session_path !== '/' && Boolean(sessionStorage.getItem('r')) && sessionStorage.getItem('r') !== window.location.href  )
                                {
                                  const r = sessionStorage.getItem('r');
                                  //sessionStorage.removeItem('r');
                                  if(!Boolean(sessionStorage.getItem('rcd')))
                                  {
                                    sessionStorage.setItem('rcd', '1');
                                    window.location.replace(r);
                                  }
                                  else
                                  {
                                    if(parseInt(sessionStorage.getItem('rcd')) > 2)
                                    {
                                      sessionStorage.removeItem('rcd');
                                      sessionStorage.removeItem('r');
                                      return window.location.replace('/home');
                                    }

                                    let inc = parseInt(sessionStorage.getItem('rcd')) ? 
                                              parseInt(sessionStorage.getItem('rcd'))+1 : 1;

                                    sessionStorage.setItem('rcd', inc.toString());
                                    window.location.replace(r);
                                  }
                        
                                }
                               
                            }

                            auth = true
                            store.dispatch(set_loading(false))
                            
                            
                        } else {

                            if(cookies)
                            {
                              cookies.remove('t');
                            }

                            store.dispatch(set_loading(false))
                            
                        }
        });
        
        return auth;
    }


    refresh_token()
    {
      const store = this.store;
      console.log('refresh token triggered from refresh_token')
       this.app.auth()
            .currentUser.getIdToken(true).then(function(idToken) {
                store.dispatch(set_token(idToken))
            }).catch( err => {
                console.log("error refreshing token from refresh_token: ", err)
            });

    }

    submit_login ({ referrer, email, password }) 
    {

      //console.log("referrer from submit login: ", referrer)
      const app = this.app;
      const store = this.store;
     
      
      if( !email || !password )
      {
        return alert("Please provide the required credentials!")
      }

      store.dispatch(set_loading(true))
    
      axios({
        method: 'post',
        url: 'https://omsvfb6en0.execute-api.us-west-2.amazonaws.com/dev/signin',
        data: {
          email: email.trim(),
          password: password.trim()
        }
      })
      .then(res => {
        return app.auth().signInWithCustomToken(res['data']['token'])
      })
      .then(() => {
        sessionStorage.setItem('auth', 'true');
        sessionStorage.setItem('selection', 'true');
        window.location.replace(referrer);
      })
      .catch(err => {
        console.log("err: ", err);
        store.dispatch(set_loading(false))
        store.dispatch(set_alert_message('Wrong Credentials'))
        store.dispatch(set_alert_type('danger'))
      })
    
    }



    submit_selection ({ selected_account, cookies }) 
    {

      const app = this.app;
      const store = this.store;
     
      
      if( !selected_account )
      {
        return alert("Something went wrong. Try again later.")
      }
      

      axios({
        method: 'post',
        url: 'https://omsvfb6en0.execute-api.us-west-2.amazonaws.com/dev/signin_retkeys',
        headers: {
          'Authorization': store.getState()['main']['token']
        },
        data: {
          selected_account
        }
      })
      .then(res => {


        if(res.status === 200)
        {
          //sessionStorage.setItem('t', res)
          sessionStorage.removeItem('selection');
          //sessionStorage.removeItem('referrer');
          
          /*
            return app.auth()
                    .signInWithCustomToken(res['data']['token'])
          */
          
            return app.auth()
                      .signOut()
                      .then(() => 
                          app.auth()
                          .signInWithCustomToken(res['data']['token']))
          
        }

        

        return true;
      })
      .then(() => window.location.replace(sessionStorage.getItem('r')))
      .catch(err => {
        console.log("err: ", err);
       
        //return window.location.replace('/selection')
      })
    
    }


    submit_logout ({ triggers_offline, cookies })
    {
      const fb_app = this.app;
      const store = this.store;
      
      
      for (let t in triggers_offline)
      {
        triggers_offline[t]({fb_app, store})
      }
      

      return fb_app.auth()
                    .signOut()
                    .then(function() {
                        // Sign-out successful.
                        alert("Successfully Signed out!")
                        sessionStorage.removeItem('selection')
                        store.dispatch(set_is_auth(false))

                        sessionStorage.removeItem("auth");
                        store.dispatch(set_is_auth(false));
                        sessionStorage.removeItem('rcd');

                        if(Boolean(sessionStorage.getItem('r')))
                        {
                          sessionStorage.removeItem('r')
                          window.location.replace("/signin");
                          
                        }

                        return true;
                      })
                    .then(() => fb_app.auth().signOut())
                    .catch(function(error) {
                        alert("Something went wrong!")
                    });
    }


    submit_signup ({ company_name, email, password, c_password }) 
    {

      const store = this.store;
      
      if(password !== c_password)
      {
        return alert("Passwords do not match!")
      }

      if(!company_name || !email || !password)
      {
        return alert("Please complete all the required fields.")
      }


      
      axios({
        method: 'post',
        url: 'https://omsvfb6en0.execute-api.us-west-2.amazonaws.com/dev/signup',
        headers: {
          'accept': 'application/json',
          'content-type': 'application/json'
        },
        data: {
          company_name: company_name.trim(),
          email: email.trim(),
          password: password.trim(),
          c_password: c_password.trim()
        }
      })
      .then(res => {
        console.log("res: ", res);
        store.dispatch(set_alert_message('An email has been sent for confirmation.'))
        store.dispatch(set_alert_type('warning'))
      })
      .catch(err => {
        console.log("err: ", err);
        console.log("err['response']: ", err['response']);
        store.dispatch(set_alert_message(''));
        store.dispatch(set_alert_type(''));
        store.dispatch(set_alert_message(err['response']['data']['message']))
        store.dispatch(set_alert_type('danger'))
      })
    
    }

}


export default Authentication;