import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import MCard from '@material-ui/core/Card';
import MCardContent from '@material-ui/core/CardContent';
import MUIDataTable from "../../custom_packages/mui-datatables/src/MUIDataTable";
//import MUIDataTable from "mui-datatables";
import { Input, Label, Row, Col} from 'reactstrap';
import Tabs from '../custom/utils/field_components/Tabs';
import MCardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';


const columns = [
    {
      name: "name",
      options: {
        filter: true,
        viewColumns: true
      }
    },
    {
      name: "title",
      options: {
        filter: true,
        viewColumns: true
      }
    },
    {
      name: "location",
      options: {
        filter: false,
        viewColumns: true
      }
    },
    {
      name: "age",
      options: {
        filter: true,
        viewColumns: true
      }
    },
    {
      name: "salary",
      options: {
        filter: true,
        sort: false,
        viewColumns: true
      }
    },
    {
      name: "salary1",
      options: {
        filter: true,
        sort: false,
        viewColumns: true
      }
    },
    {
      name: "salary2",
      options: {
        filter: true,
        sort: false,
        viewColumns: true
      }
    }
  ];
 
const data = [
 { name: "Joe James", company: "Test Corp", city: "Yonkers", state: "NY" },
 { name: "John Walsh", company: "Test Corp", city: "Hartford", state: "CT" },
 { name: "Bob Herm", company: "Test Corp", city: "Tampa", state: "FL" },
 { name: "James Houston", company: "Test Corp", city: "Dallas", state: "TX" },
];


const data2 = [
    ["Gabby George", "Business Analyst", "Minneapolis", 30, "$100,000", "$100,000", "$100,000"],
    ["Aiden Lloyd", "Business Consultant", "Dallas",  55, "$200,000", "$200,000", "$200,000"],
    ["Jaden Collins", "Attorney", "Santa Ana", 27, "$500,000", "$500,000", "$500,000"],
    ["Franky Rees", "Business Analyst", "St. Petersburg", 22, "$50,000", "$50,000", "$50,000"],
    ["Aaren Rose", "Business Consultant", "Toledo", 28, "$75,000", "$75,000", "$75,000"],
    ["Blake Duncan", "Business Management Analyst", "San Diego", 65, "$94,000", "$94,000", "$94,000"],
    ["Frankie Parry", "Agency Legal Counsel", "Jacksonville", 71, "$210,000", "$210,000", "$210,000"],
    ["Lane Wilson", "Commercial Specialist", "Omaha", 19, "$65,000", "$65,000", "$65,000"],
    ["Robin Duncan", "Business Analyst", "Los Angeles", 20, "$77,000", "$77,000", "$77,000"],
    ["Mel Brooks", "Business Consultant", "Oklahoma City", 37, "$135,000", "$135,000", "$135,000"],
    ["Harper White", "Attorney", "Pittsburgh", 52, "$420,000", "$420,000", "$420,000"],
    ["Kris Humphrey", "Agency Legal Counsel", "Laredo", 30, "$150,000", "$150,000", "$150,000"],
    ["Frankie Long", "Industrial Analyst", "Austin", 31, "$170,000", "$170,000", "$170,000"],
    ["Brynn Robbins", "Business Analyst", "Norfolk", 22, "$90,000", "$90,000", "$90,000"],
    ["Justice Mann", "Business Consultant", "Chicago", 24, "$133,000", "$133,000", "$133,000"],
    ["Addison Navarro", "Business Management Analyst", "New York", 50, "$295,000", "$295,000", "$295,000"],
    ["Jesse Welch", "Agency Legal Counsel", "Seattle", 28, "$200,000", "$200,000", "$200,000"],
    ["Eli Mejia", "Commercial Specialist", "Long Beach", 65, "$400,000", "$400,000", "$400,000"],
    ["Gene Leblanc", "Industrial Analyst", "Hartford", 34, "$110,000", "$110,000", "$110,000"],
    ["Danny Leon", "Computer Scientist", "Newark", 60, "$220,000", "$220,000", "$220,000"],
    ["Lane Lee", "Corporate Counselor", "Cincinnati", 52, "$180,000", "$180,000", "$180,000"],
    ["Jesse Hall", "Business Analyst", "Baltimore", 44, "$99,000", "$99,000", "$99,000"],
    ["Danni Hudson", "Agency Legal Counsel", "Tampa", 37, "$90,000", "$90,000", "$90,000"],
    ["Terry Macdonald", "Commercial Specialist", "Miami", 39, "$140,000", "$140,000", "$140,000"],
    ["Justice Mccarthy", "Attorney", "Tucson", 26, "$330,000", "$330,000", "$330,000"],
    ["Silver Carey", "Computer Scientist", "Memphis", 47, "$250,000", "$250,000", "$250,000"],
    ["Franky Miles", "Industrial Analyst", "Buffalo", 49, "$190,000", "$190,000", "$190,000"],
    ["Glen Nixon", "Corporate Counselor", "Arlington", 44, "$80,000", "$80,000", "$80,000"],
    ["Gabby Strickland", "Business Process Consultant", "Scottsdale", 26, "$45,000", "$45,000", "$45,000"],
    ["Mason Ray", "Computer Scientist", "San Francisco", 39, "$142,000", "$142,000", "$142,000"]
  ];


const sample_profile_blueprint = [{
  type:'layout',
  label: 'Animal Profile',
  name: 'animal_profile',
  access_roles: ['all','admin'],
  layout : [
      // ROW
      {
          type: 'form',
          label: 'Primary Information',
          name: 'primary_info',
          column_count: 2,
          access_roles: ['all','admin'],
          columns: [
              [
                  [{
                      type: 'text',
                      label: 'Record ID',
                      name: 'record_id',
                      component: 'input_text',
                      access_roles: ['all','admin'],
                      locked: true,
                      constant: true,
                      placeholder: 'To Be Generated',
                      disabled: true
                  }],
                  [{ 
                      type: 'media',
                      label: 'Profile Image',
                      name: 'profile_image',
                      component: 'upload_image',
                      access_roles: ['all','admin'],
                      locked: true,
                      constant: true,
                  }]
              ],
              [
                  [{
                      type: 'text',
                      label: 'Record Active',
                      name: 'record_active',
                      component: 'input_text',
                      access_roles: ['all','admin'],
                      locked: true,
                      disabled: true
                  },
                  {
                      type: 'text',
                      label: 'Date Created',
                      name: 'date_created',
                      component: 'input_text',
                      access_roles: ['all','admin'],
                      locked: true,
                      constant: true,
                      placeholder: 'To Be Generated',
                      disabled: true
                  }],
                  [{
                      type: 'text',
                      label: 'Category',
                      name: 'category',
                      component: 'input_text',
                      access_roles: ['all','admin'],
                      locked: true,
                      constant: false,
                  }],
                  [{
                      type: 'text',
                      label: 'Name',
                      name: 'name',
                      component: 'input_text',
                      access_roles: ['all','admin'],
                      locked: true,
                      constant: false
                  }]
                  ,
                  [{
                      type: 'number',
                      label: 'Age',
                      name: 'age',
                      component: 'input_text',
                      access_roles: ['all','admin'],
                      locked: false
                  },
                  {
                      type: 'text',
                      label: 'Gender',
                      name: 'gender',
                      component: 'input_text',
                      access_roles: ['all','admin'],
                      locked: true
                  }],
                  [{
                      type: 'create_button',
                      label: 'Create Record',
                      name: 'create_record',
                      component: 'create_record_button',
                      color: 'primary',
                      access_roles: ['all','admin'],
                      locked: true,
                      constant: true,
                  }]
              ]
          ],
      },
      {
          type: 'divider',
          label: 'Default: End Of Div',
          name: 'primary_info',
          component: 'row_divider'
      },
      {
          type: 'tabs',
          label: 'Related Records and Documents',
          name: 'related_records_documents',
          access_roles: ['all','admin'],
          component: ''
      },
      {
          type: 'divider',
          label: 'Default: End Of Div',
          name: 'primary_info',
          component: 'row_divider'
      },
  ]
}];






const sample_sub_tab = [
  {
      type: 'directory',
      name: 'Medical Documents',
  },
  {
      type: 'directory',
      name: 'Medical Documents/Medical Images',
  },
  {
      type: 'directory',
      name: 'Medical Documents/Medical Videos',
  },
  {
      type: 'directory',
      name: 'Medical Documents/Medical Images/Today',
  },
  {
      type: 'file',
      name: 'Medical Documents/Medical Images/Today/sample.csv',
  },
  {
      type: 'file',
      name: 'Medical Documents/Medical Images/Today/sample1.csv',
  },
  {
      type: 'file',
      name: 'Medical Documents/Medical Images/Today/sample2.csv',
  },
  {
      type: 'file',
      name: 'Medical Documents/Medical Images/Today/sample3.csv',
  },
  {
      type: 'file',
      name: 'Medical Documents/Medical Images/Today/sample4.csv',
  },
  {
      type: 'file',
      name: 'Medical Documents/Medical Images/Today/sample5.csv',
  },
  {
      type: 'directory',
      name: 'Medical Documents/Medical Images/Yesterday',
  },
  {
      type: 'directory',
      name: 'Medical Documents/Medical Images/This Week',
  },
  {
      type: 'directory',
      name: 'Medical Documents/Medical Images/This Month',
  },
  {
      type: 'directory',
      name: 'Medical Documents/Medical Images/This Year',
  },
  {
      type: 'file',
      name: 'Medical Documents/Medical Images/image1.csv',
  },
  {
      type: 'file',
      name: 'Medical Documents/Medical Images/image2.csv',
  },
  {
      type: 'file',
      name: 'Medical Documents/Medical Images/image3.csv',
  },
  {
      type: 'file',
      name: 'Medical Documents/Medical Images/Yesterday/sample.csv',
  },
  {
      type: 'directory',
      name: 'Laboratory Results',
  },
  {
      type: 'directory',
      name: 'Laboratory Results/Lab Images',
  },
  {
      type: 'directory',
      name: 'Laboratory Results/Lab Videos',
  },
  {
      type: 'directory',
      name: 'Reports',
  },
  {
      type: 'directory',
      name: 'Reports/Reports Images',
  },
  {
      type: 'directory',
      name: 'Reports/Reports Videos',
  },
  /*
  {
      type: 'directory',
      name: 'samples',
  },
  {
      type: 'directory',
      name: 'tests',
  },
  {
      type: 'directory',
      name: 'blood',
  },
  {
      type: 'directory',
      name: 'stool',
  },
  */
]





 


const fil = data2.map(d => ({ name: d[0], title: d[1], location: d[2], age: d[3], salary: d[4], salary1: d[5], salary2: d[6] }))

console.log(fil)



function validURL(str) {
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(str);
}


const Record_Collection = ({ columns, data, isMobile }) => {
  
  // ref for printing


  console.log('columns: ', columns)
  console.log('data: ', data)

  const expandableRow = (rowData, rowMeta) => {
    console.log('rowData: ', rowData)
    console.log('rowMeta: ', rowMeta)
    return  <tr style={{backgroundColor: 'rgb(240, 240, 240)'}}>
             <td colspan="12">
              <div style={{padding: '30px', width: '100%'}}>
                
                  <div style={{height: 'auto', display: 'flex', flexFlow: 'row nowrap', alignItems: 'flex-start', justifyContent: 'flex-start'}}>
                    <MCard style={{width: '500px', margin: '15px 35px 25px 35px', height: '100%'}} elevation={6}>
                    <MCardHeader
                        action={
                          <IconButton aria-label="settings">
                            <MoreVertIcon />
                          </IconButton>
                        }
                        title="Quick View"
                        //subheader="September 14, 2016"
                      />
                    <MCardContent>
                    <div style={{width: '100%', display: 'flex', flexFlow: 'row wrap', justifyContent: 'space-around'}}>
                      {
                        rowData.map((d, i) => ( <div style={{width: '225px', margin: '6px 1.5px 6px 1.5px', display: 'flex', flexFlow: 'column nowrap', border: '0px 0px 1px 1px'}}>
                                                  <Label htmlFor={columns[i]['name']} style={{width: '140px'}}>
                                                    {columns[i]['name']}
                                                  </Label>
                                                  {
                                                    Boolean(validURL(d)) == true ?
                                                    <img style={{width: '160px'}} src ={d} /> :
                                                    <h5>
                                                      {
                                                        Array.isArray(d) ? 
                                                        d.map( role => <h5>{role}</h5>) : 
                                                        <span>{d}</span>
                                                      }
                                                    </h5>
                                                  }
                                                </div> ))
                      }
                    </div>
                    </MCardContent>
                    </MCard>
                    <MCard style={{width: '700px', margin: '15px 6px 25px 12px', height: '100%'}} elevation={6}>
                    <MCardContent>
                    
                      <Tabs name="tabs" id="tabs" sub_tabs={sample_sub_tab} isMobile={isMobile} />

                    </MCardContent>
                    </MCard>
                  </div>
              
            </div>
           </td>
          </tr>
  }

  /*
  const options = {
    filter: true,
    filterType: 'dropdown',
    responsive: 'scrollMaxHeight',
    expandableRowsOnClick: true,
    expandableRows: true,
    renderExpandableRow: expandableRow,
    fixedHeaderOptions: {
      xAxis: false,
      yAxis: true
    },
  };*/


  const options = {
    filter: true,
    selectableRows: 'multiple',
    filterType: 'dropdown',
    expandableRowsOnClick: true,
    expandableRows: true,
    renderExpandableRow: expandableRow,
    fixedHeaderOptions: {
      xAxis: true,
      yAxis: true
    },
    rowsPerPage: 10,
    responsive: 'standard',
    //responsive: 'scrollMaxHeight',
    draggableColumns: {
      enabled: true,
      transitionTime: 500
    },
    downloadOptions: {
        filename: 'filename.csv',
        separator: ',',
        filterOptions: {
          useDisplayedColumnsOnly: true,
          useDisplayedRowsOnly: true,
        }
    },
    onDownload: (buildHead, buildBody, columns, data) => {
      let val= `${buildHead(columns)}${buildBody(data)}`.trim();
      return val;
    },
    onRowSelectionChange: (currentRowsSelected, allRows, rowsSelected) => {
      console.log(currentRowsSelected, allRows, rowsSelected);
    },
    onRowsDelete: rowsDeleted => {
      console.log(rowsDeleted, 'were deleted!');
    },
    onChangePage: numberRows => {
      console.log(numberRows);
    },
    onSearchChange: searchText => {
      console.log(searchText);
    },
    onColumnSortChange: (column, direction) => {
      console.log(column, direction);
    },
    onViewColumnsChange: (column, action) => {
      console.log(column, action);
    },
    onFilterChange: (column, filters) => {
      console.log(column, filters);
    },
    onCellClick: (cellIndex, rowIndex) => {
      console.log(cellIndex, rowIndex);
    },
    onRowClick: (rowData, rowState) => {
      console.log(rowData, rowState);
    },
  };

  return (<MUIDataTable
            className='Mui-Table'
            title={"Animal Profile"}
            data={data}
            columns={columns}
            options={options} />)
};

export default Record_Collection;