import Base_Config from '../Base_Config';
import {
    set_sidebar_open
} from '../../../redux/actions';


class Sidebar extends Base_Config
{
    constructor({app, store})
    {
        super({ app, store });
        this.toggle_open = this.toggle_open.bind(this);
    }

    toggle_open({ open })
    {
        this.store.dispatch(set_sidebar_open(open));
    }
}


export default Sidebar;