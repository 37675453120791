

function Get_Users({ fb_app, store })
{
    const account_id = store.getState()['main']['account_id'];
   

    return fb_app.database()
                .ref(`registered_accounts/${account_id}`)
                .child('users')
                .on('value', snapshot => {
                    console.log("users snapshot: ", snapshot.val())
                })
    
}


export default Get_Users;