import firebase from 'firebase';
import firebase_config from '../../firebase_config';
import store from '../../redux/store/index';
import { Sidebar, Authentication, Create_Record, Alert, Get_Records_Summary, Get_Sample_Selection, Get_Record_Blueprints } from './actions';
import { Get_User, Set_Status_Online, Set_Status_Offline, Get_Data_Usage } from './triggers';

const app = !firebase.apps.length ? 
firebase.initializeApp(firebase_config) :
firebase.app();



class Controller 
{
    constructor()
    {
        this.actions = {
            Alert: new Alert({ app, store }),
            Sidebar: new Sidebar({ app, store }),
            Authentication: new Authentication({ app, store }),
            Create_Record: new Create_Record({ app, store }),
            Get_Records_Summary: new Get_Records_Summary({app, store}),
            Get_Sample_Selection: new Get_Sample_Selection({app, store}),
            Get_Record_Blueprints: new Get_Record_Blueprints({app, store})
        }

        this.triggers_online = {
            Get_User,
            Set_Status_Online,
            Get_Data_Usage
        }

        this.triggers_offline = {
            Set_Status_Offline
        }


        this.set_alert = this.set_alert.bind(this);
        this.on_click = this.on_click.bind(this);
        this.check_auth = this.check_auth.bind(this);
        this.get_records_summary = this.get_records_summary.bind(this);
    }



    set_alert({ type, message })
    {
        this.actions['Alert'].set_alert({ type, message })
    }


    on_click ({ app_class, btn_name, parameters })
    {
        this.actions[app_class][btn_name]({ triggrers_online: this.triggers_online, 
                                            triggers_offline: this.triggers_offline, 
                                            ...parameters });
    }

    check_auth()
    {
        this.actions['Authentication'].check_auth({ triggers_online: this.triggers_online, 
                                                    triggers_offline: this.triggers_offline });
    }

    get_records_summary()
    {
        
        return this.actions['Get_Records_Summary'].get_records_summary({ triggers_online: this.triggers_online, 
                                                                         triggers_offline: this.triggers_offline });
    }

    get_record_blueprints()
    {
        return this.actions['Get_Record_Blueprints'].get_record_blueprints({ triggers_online: this.triggers_online, 
                                                                             triggers_offline: this.triggers_offline });
    }

    
}


export default Controller;