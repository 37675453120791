import React, {Component} from 'react';
import NewRecord from '../../custom/New_Record';
import Loader from '../Loader';
import { connect } from 'react-redux';
import { SubNavigation } from '../../../vibe';



const mapStateToProps = (state) => {
  return {
    token: state.main.token,
    record_blueprints: state.record_details.record_blueprints
  };
};






class Create_New_Record extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: false,
      record_blueprints: [],
      blueprints_available: false
    }

    this.process_blueprints = this.process_blueprints.bind(this);
  }


  componentDidMount()
  {
    const { isMobile, controller, token, record_blueprints } = this.props;
    
    let blueprints_available = false;
    let layouts = {
      layouts: [],
      file_layouts: [],
      opts: []
    }

    if(token)
    {
      controller.get_record_blueprints();
    }

    if (record_blueprints.length > 0)
    {
      layouts = this.process_blueprints(record_blueprints);;
      blueprints_available = true
    }

    console.log('layouts: ', layouts)

    this.setState({...this.state, isMobile, controller, record_blueprints, layouts, blueprints_available})
  }


  componentDidUpdate(prevProps, PrevState)
  {
    const { isMobile, controller, token, record_blueprints } = this.props;

    if(prevProps.isMobile !== isMobile)
    {
      this.setState({...this.state, isMobile, controller})
    }

    if(prevProps.controller !== controller)
    {
      this.setState({...this.state, isMobile, controller})
    }

    if(prevProps.token !== token)
    {
      controller.get_record_blueprints();
    }

    if(prevProps.record_blueprints !== record_blueprints)
    {
      if (record_blueprints.length > 0)
      {
        const layouts = this.process_blueprints(record_blueprints);
        this.setState({...this.state, layouts, blueprints_available: true});
      }
    }
   
  }



  process_blueprints(record_blueprints)
  {
      const layouts_body = {};
      const file_layouts_body = {};
      const layouts = [];
      const file_layouts = [];
      const opts_main = [];


      for(let blueprint of record_blueprints)
      {
        const {related_files, ...bp} = blueprint
        layouts.push(bp);
        file_layouts.push(related_files);
      }

      let idx = 0;
      for(let format of layouts)
      {
        opts_main.unshift({name: format['name'], label: format['label']})
        layouts_body[format['name']] = [format];
        file_layouts_body[format['name']] = file_layouts[idx++]
      }
      
      
      return { layouts: layouts_body, file_layouts: file_layouts_body, opts: opts_main }
  }


 
  render() {
    const { isMobile, controller, blueprints_available, layouts } = this.state;


    let display = blueprints_available ? 
                  <NewRecord is_mobile={isMobile} layouts={layouts} controller={controller} /> : 
                  <Loader />
  
    return (display)
  }
}



export default connect(mapStateToProps, null)(Create_New_Record);


