import React from 'react';
import * as Feather from 'react-feather';
import { FormGroup, Col, Row, Card, CardBody } from 'reactstrap';
import Tabs from '../field_components/Tabs';
import Field_Components from '../field_components';
import { v4 as uuidv4 } from 'uuid';
import Loader from '../../../pages/Loader';








class Layout_To_Ui extends React.Component {

    constructor(props)
    {
        super(props);

        this.state = {
            display: '',
            form_ident: uuidv4()
        }
    }


    componentDidMount()
    {
        this.setState({...this.state, form_ident: uuidv4() })
    }



    render ()
    {
       
        const { record_type, sub_tabs, is_mobile, type_selected, controller, record_id_placeholder, record_is_active, customize_id } = this.props;


        const id = type_selected.split('_')
                                .map(w => {
                                    return w.split('')[0]
                                })
                                .join('')
                                .toUpperCase()

        const record_id = `${id}${1}`;


        return (
            record_type && record_type.length > 0 ?
            <Card>
                <CardBody>
                {
                    record_type.map( (layoutObj) => 
                    {
                        // Loop in layout array
                        let d = layoutObj['layout'].map( (obj) => 
                        {
                            // Check if is divider
                            if(obj['type'] === 'divider')
                            {
                                return <Row className='cust_divider' />
                            }
                            else if (obj['type'] === 'form')
                            {
                                // Proceed with layout array (rows)
                                return <>
                                        {/** ROW LABEL */}
                                        <h4 className='label_header'>{obj['label']}</h4>
                                        {
                                            <Row style={{flexDirection: is_mobile ? 'column': 'row'}}>
                                                {
                                                    // ROW COLUMNS
                                                    obj['columns'].map(col => {

                                                            return  (
                                                                // Column loop in
                                                                <Col> 
                                                                { 
                                                                    // fields layout array (row)
                                                                    col.map( c => {

                                                                        return (
                                                                            <Row>
                                                                            {
                                                                                // Retrieving fields
                                                                                c.map( fields => 
                                                                                { 
                                                                                    
                                                                                    
                                                                                    let Comp = Field_Components[fields['component']]({  fields, 
                                                                                                                                        form_ident: this.state.form_ident, 
                                                                                                                                        record_type: type_selected, 
                                                                                                                                        record_id,
                                                                                                                                        controller,
                                                                                                                                        record_id_placeholder,
                                                                                                                                        record_is_active,
                                                                                                                                        customize_id   })

                                                                                    return ( 
                                                                                            <Col>
                                                                                                <FormGroup>    
                                                                                                        
                                                                                                    { Comp }
                                                                                                    
                                                                                                </FormGroup> 
                                                                                            </Col>
                                                                                            )
                                                                                })
                                                                            }
                                                                            </Row>
                                                                        )

                                                                    })
                                                                }
                                                                </Col>
                                                            )
                                                        
                                                    })
                                                }
                                            </Row>
                                        
                                        }   
                                        </> ;
                            }
                            else if (obj['type'] === 'tabs')
                            {
                                // Proceed with layout array (rows)
                                return <>
                                        {/** ROW LABEL */}
                                        <h4 className='label_header'>{obj['label']}</h4>
                                        {
                                            <Row>
                                                {
                                                  <Col>
                                                    {
                                                        <Tabs name="tabs" id="tabs" sub_tabs={sub_tabs} is_mobile={is_mobile} />
                                                    }
                                                  </Col>
                                                }
                                            </Row>
                                        
                                        }   
                                        </> ;
                            }
                            
                        })

                        return d;
                    }) 
                }
                </CardBody>
            </Card> :
            <Loader />
        )
    }
}



export default Layout_To_Ui;