//import { ApolloClient, ApolloLink, InMemoryCache, HttpLink } from 'apollo-boost';
import { IS_AUTH, 
         USER_NAME,
         SIDEBAR_OPEN,
         ACCOUNT_ID,
         USER_FRONT_KEY,
         ACCOUNTS_AVAILABLE,
         TOKEN,
         LOADING,
         ALERT_MESSAGE,
         ALERT_TYPE, 
         REFERRER,
         RECORDS_DETAILS,
         SAMPLE_SELECTED_RECORD,
         BROWSE_RECORDS,
         BROWSE_RECORDS_COLUMN_HEADERS,
         DATA_USAGE_BYTES,
         RECORD_BLUEPRINTS } from "../constants/action-types";


export const set_is_auth = param => ({type: IS_AUTH, payload: param});
export const set_user_name = param => ({type: USER_NAME, payload: param});
export const set_sidebar_open = param => ({type: SIDEBAR_OPEN, payload: param});
export const set_account_id = param => ({type: ACCOUNT_ID, payload: param});
export const set_user_front_key = param => ({type: USER_FRONT_KEY, payload: param});
export const set_accounts_available = param => ({type: ACCOUNTS_AVAILABLE, payload: param});
export const set_token = param => ({type: TOKEN, payload: param});
export const set_loading = param => ({type: LOADING, payload: param});
export const set_alert_message = param => ({type: ALERT_MESSAGE, payload: param});
export const set_alert_type = param => ({type: ALERT_TYPE, payload: param});
export const set_referrer = param => ({type: REFERRER, payload: param});
export const set_records_details = param => ({type: RECORDS_DETAILS, payload: param});
export const set_sample_selected_record = param => ({type: SAMPLE_SELECTED_RECORD, payload: param});
export const set_browse_records = param => ({type: BROWSE_RECORDS, payload: param});
export const set_browse_records_column_headers = param => ({type: BROWSE_RECORDS_COLUMN_HEADERS, payload: param});
export const set_data_usage_bytes = param => ({type: DATA_USAGE_BYTES, payload: param});
export const set_record_blueprints = param => ({type: RECORD_BLUEPRINTS, payload: param});


