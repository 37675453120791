
import React, { useEffect, useState } from 'react';
import MButton from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { connect } from 'react-redux';





const mapStateToProps = (state) => {
    return {
      is_auth: state.main.is_auth,
      accounts_available: state.main.accounts_available,
      user_email: state.main.user_name
    };
  };



function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="/#">
        www.flexyz.com
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}




const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));




const ColorButton = withStyles((theme) => ({
  root: {
    width: '100%',
    height: '100px',
    marginTop: '2.7rem',
    marginBottom: '4.5rem',
    color: '#00717b',
    fontSize: '2.4rem',
    backgroundColor: 'none',
    border: '1px solid #ccc',
    '&:hover': {
      backgroundColor: '#004d54',
      color: 'white',
      boxShadow: '0px 3px 12px 1px black',
    },
  },
}))(MButton);











function Sign_In_Selection({ controller, is_auth, accounts_available, user_email, cookies }) {
  const classes = useStyles();
  const [state, setState] = useState({
      //is_auth,
      //accounts_available,
      //user_email,
      refresh_time: 0
  })
  let timer = 0;

  let accounts_display = [];


  console.log('cookies in selection: ', cookies)

  useEffect(() => {
        if(accounts_available)
        {
            setState({
                ...state,
                is_auth,
                accounts_available
            })

        }
  },[accounts_available]);


  useEffect(() => {

    if(cookies) 
    {
      //controller.on_click({app_class: 'Authentication', btn_name: 'check_auth', parameters: {cookies}})
    }

  },[]);


  const refresh_timer = () => {
    console.log("!!!!!! refreshing timer  !!!!!")
    timer = 0;
  }

  useEffect(() => {
    const interval = setInterval(() => 
    {
     
      timer += 1;
      

      console.log("selection refresh!!" , timer)

      if(timer >= 57)
      {
        console.log("restarting timer")
        refresh_timer();
        console.log("selection refresh triggered!")
        controller.on_click({app_class: 'Authentication', btn_name: 'refresh_token'}) 
      }
     
                  
    }, 60000)

    return () => clearInterval(interval);
  }, []);

  


  if(accounts_available)
  {
    let i = 0;
    for(let c of accounts_available)
    {
      
        accounts_display.push( <ColorButton
                                key={i++}
                           
                             
                             
                                onClick={e => submit_selection(e, c)}
                              
                                elevation={9}
                                >
                                { c.replace(/_/g, ' ') }

                               </ColorButton>)
    }
  }


  function submit_selection(event, selected_account)
  {
    return controller.on_click({app_class: 'Authentication', btn_name: 'submit_selection', parameters: { selected_account } })
  }

  

  let display = <Container component="main" maxWidth="xs">
                  <CssBaseline />
                  <div className={classes.paper}>

                  <Typography component="h1" variant="h5" style={{color: '#004d54'}}>
                      Select Company
                  </Typography>
                  <form className={classes.form} >
                      {
                        accounts_display
                      } 
                  </form>
                  </div>
                  <Box mt={8}>
                  <Copyright />
                  </Box>
                </Container>
 


  return display;
}

export default connect(mapStateToProps, null)(Sign_In_Selection);

/*
import React, {Component} from 'react';
import { Button, Form, FormGroup, Input, Card, CardBody, CardFooter } from 'reactstrap';


export default class FormsPage extends Component {

  constructor(props) {
    super(props);
    this.state = { }
  }

  handle_sign_in (e)
  {
    e.preventDefault();
    const { controller, referrer } = this.props;
    const account = document.querySelector('#login-account').value;
    const email = document.querySelector('#login-email').value;
    const password = document.querySelector('#login-password').value;

    return controller.on_click({app_class: 'Authentication', btn_name: 'submit_login', parameters: {referrer, account, email, password} })

  }

  render() 
  {
    return (
      <div className='login-container'>

        <Card id='login-card'>
          <CardBody>
            <Form id='login-form' onSubmit={this.handle_sign_in}>
              <FormGroup>
                <h2 id='login-title'>FLEXYZ</h2>
              </FormGroup>

              <FormGroup>
                <Input type='text' name='login-account' id='login-account' placeholder='Account' />
              </FormGroup>

              <FormGroup>
                <Input type='text' name='login-email' id='login-email' placeholder='Email' />
              </FormGroup>

              <FormGroup>
                <Input type='text' name='login-password' id='login-password' placeholder='Password' />
              </FormGroup>

              <Button color='primary'>Login</Button>
            </Form>
          </CardBody>
          <CardFooter id='login-card-footer'>
            <a href='/#'>Forgot password?</a>
            <a href='/#'>Don't have an account? Sign up!</a>
          </CardFooter>
        </Card>
         
      </div>
    )
  }

}
*/