import React from 'react';
import { Button } from 'reactstrap';



function Create_Record ({ fields, form_ident, record_type, controller, customize_id, record_is_active })
{

    function submit_action (e)
    {
        const collected_inpts = document.querySelectorAll(`[data-form-ident="${form_ident}"]`);

        console.log('collected_inpts: ', collected_inpts);

        let modeled = []
        for(let e of collected_inpts)
        {

            if(e.getAttribute('data-form-value') || e['value'])
            {
                modeled.push({
                    type: e.getAttribute('data-form-type'),
                    label: e.getAttribute('data-form-label'),
                    name: e.getAttribute('data-form-name'),
                    record_id: e.getAttribute('data-form-record-id'),
                    placeholder: e.getAttribute('data-form-placeholder'),
                    date_created: 'To Be Generated',
                    value: e.getAttribute('data-form-value') || e['value'],
                    access_roles: e.getAttribute('data-form-access-roles').split(',') || ['all'],
                    //component: e.getAttribute('data-form-component'),
                    record_type,
                    
                });
            }
            
        }

        console.log("modeled: ", modeled)

        if(modeled.length > 0)
        {
           
            console.log('controller: ', controller)
            controller.on_click({app_class: 'Create_Record', btn_name: 'submit_create_record', parameters: { fieldsets: modeled, record_id_source: customize_id ? 'custom_id' : 'auto_generate', record_is_active }})
        }
    }



    return <Button style={{width: '100%'}} color={fields['color']} onClick={submit_action}><i className="fa fa-check"></i>&nbsp;{ fields['label']}</Button>
}


export default Create_Record;