import React, {Component} from 'react';
import Loader from '../Loader';
import { connect } from 'react-redux';
import FormBuilder from "../../../custom_packages/react-form-builder/src/index";
import DemoBar from "../../../custom_packages/react-form-builder/demobar";






var items = [{
  key: 'Header',
  name: 'Header Text',
  icon: 'fa fa-header',
  static: true,
  content: 'Placeholder Text...'
},
{
  key: 'Paragraph',
  name: 'Paragraph',
  static: true,
  icon: 'fa fa-paragraph',
  content: 'Placeholder Text...'
}];





const mapStateToProps = (state) => {
  return {
    token: state.main.token,
    record_blueprints: state.record_details.record_blueprints
  };
};





class Builder extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isMobile: false,
      record_blueprints: [],
      blueprints_available: false
    }

  }

 
  render() {
    const { isMobile, controller, blueprints_available, layouts } = this.state;

    let display = <div >
                      <DemoBar />
                      <FormBuilder.ReactFormBuilder />
                    </div>

  
    return display
  }

}



export default connect(mapStateToProps, null)(Builder);


