import React, { useState } from 'react';
import * as Feather from 'react-feather';
import { Input, Label, Row, Col} from 'reactstrap';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';



const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  }));


class Input_Text extends React.Component //({fields, form_ident, record_id_placeholder, record_is_active})
{

    constructor(props)
    {
        super(props);
        this.state = {
            anchorEl: null
        }

        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }


   
    handleClick = (event) => {
        this.setState({...this.state, anchorEl: event.currentTarget});
    };

    handleClose = () => {
        this.setState({...this.state, anchorEl: null});
    };


    render ()
    {
        const { fields, record_id_placeholder, record_is_active, form_ident } = this.props;
        const { anchorEl } = this.state;

        const LockImage = fields['locked'] ? Feather.Lock : Feather.Unlock;
        const open = Boolean(anchorEl);
        const id = open ? 'simple-popover' : undefined;

        //console.log('record_is_active: ', record_is_active)

        return  <>
                    <Label htmlFor={fields['name']} style={{width: '100%'}}>
                        {fields['label']}
                        <IconButton onClick={this.handleClick} aria-describedby={id} variant="contained" style={{outline: 'none', width: '42px', height: '42px',position: 'absolute', top: '-10px', right: '0'}} aria-label="more">
                            <Feather.MoreHorizontal size='18' color={'#004d54'} className="lock_icon" />
                        </IconButton>
                        <Popover

                            id={id}
                            open={open}
                            anchorEl={this.state.anchorEl}
                            onClose={this.handleClose} 
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            >
                            {
                               <Row>
                                 <Col colSpan="2" style={{padding: '12px 10%', maxWidth: '320px', minHeight: '60px', display: 'flex', flexFlow: 'column nowrap', justifyContent: 'center'}}>
                                    <h5 style={{width: '100%'}}>Name: {fields['name']}</h5>

                                    <div style={{width: '100%', display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-between'}}>
                                        <LockImage size='20' style={{color: fields['locked'] ? fields['constant'] ? '#c43b00' : '#d4b400' : '#004d54'}} />
                                        <span style={{width: '85%', marginLeft: '0'}}>
                                            {
                                                fields['locked'] ?
                                                fields['constant'] ? 
                                                <p>Modifying this field after creation is not allowed.</p> :
                                                <p>Modifying this field after creation is restricted to allowed roles.</p> :
                                                <p>Modifying this field after creation is allowed.</p>
                                            }
                                        </span>
                                    </div>
                                 </Col>
                               </Row>
                            }
                        </Popover>
                    </Label>
                    <Input type={fields['type']} 
                        data-form-ident={form_ident} 
                        data-form-type={fields['type']} 
                        data-form-label={fields['label']} 
                        data-form-name={fields['name']}
                        data-form-access-roles={fields['access_roles'].join(',')} 
                        data-form-component={fields['component']} 
                        data-form-record-id={record_id_placeholder}
                        data-form-record-active={record_is_active.toString()}
                        data-form-placeholder={fields['name'] === 'record_id' &&
                                                Boolean(record_id_placeholder) ? 
                                                record_id_placeholder :
                                                !Boolean(record_id_placeholder) ? 
                                                'To Be Generated' :
                                                fields['name'] === 'record_active' ?
                                                record_is_active.toString() :
                                                fields['placeholder'] ? 
                                                fields['placeholder'] : 
                                                fields['label']} 
                        placeholder={fields['name'] === 'record_id' &&
                                        Boolean(record_id_placeholder) ? 
                                        record_id_placeholder :
                                        fields['name'] === 'record_active' ?
                                        record_is_active.toString() :
                                        fields['placeholder'] ? 
                                        fields['placeholder'] : 
                                        fields['label']} 
                        disabled={Boolean(fields['disabled'])} />
                </> 
    }

    
}


export default Input_Text;



 