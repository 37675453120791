

class Base_Config
{
    constructor({ app, store })
    {
        this._app = app;
        this._store = store;
    }

    get app()
    {
        return this._app;
    }

    set app(a)
    {
        this._app = a;
    }

    get store()
    {
        return this._store;
    }

    set store(s)
    {
        this._store = s;
    }
}


export default Base_Config;